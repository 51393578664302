@charset "UTF-8";
/*Primary Colors*/
/*Accent Colors*/
/*Primary Colors*/
/*Accent Colors*/
/*Primary Colors*/
/*Accent Colors*/
/*Primary Colors*/
/*Accent Colors*/
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
.offerWrap {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
}
.offerWrap .offerleftlist {
  width: 45%;
}
@media (max-width: 991px) {
  .offerWrap .offerleftlist {
    display: none;
  }
}
.offerWrap .offerleftlist .top-detail {
  margin-top: 150px;
}
.offerWrap .offerleftlist .offerList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.offerWrap .offerleftlist .offerList ul li {
  min-height: 63px;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.offerWrap .offerleftlist .offerList ul li i {
  margin-right: 10px;
  font-size: 24px;
  color: #223bb4;
}
.offerWrap .offerleftlist .offerList ul li:not(:last-child) {
  border-bottom: 1px dashed rgb(216, 216, 216);
}
.offerWrap .offerrightlist {
  width: 55%;
  display: flex;
}
@media (max-width: 991px) {
  .offerWrap .offerrightlist {
    width: 100%;
    flex-direction: column;
  }
}
.offerWrap .offerBlock {
  flex: 1;
  padding: 24px 0px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 24px 0px;
  width: 200px;
  position: relative;
  border-radius: 16px 0 0 16px;
  -webkit-border-radius: 16px 0 0 16px;
  -moz-border-radius: 16px 0 0 16px;
  -ms-border-radius: 16px 0 0 16px;
  -o-border-radius: 16px 0 0 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 991px) {
  .offerWrap .offerBlock {
    flex: none;
    width: 100%;
    text-align: left;
    align-items: start;
  }
  .offerWrap .offerBlock .percentageBlockWrap {
    padding: 0px 30px;
  }
}
.offerWrap .offerBlock .percentageBlock {
  background: rgba(34, 34, 34, 0.03);
  padding: 3px 10px 13px;
  border-radius: 12px;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.offerWrap .offerBlock .percentageBlock span {
  margin-top: -15px;
}
.offerWrap .offerBlock ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.offerWrap .offerBlock ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 63px;
  font-size: 18px;
}
.offerWrap .offerBlock ul li i {
  font-size: 24px;
  color: #b1b1b1;
}
@media (max-width: 991px) {
  .offerWrap .offerBlock ul li {
    padding: 0 30px;
    justify-content: start;
  }
  .offerWrap .offerBlock ul li div {
    align-items: center;
  }
  .offerWrap .offerBlock ul li div i {
    font-size: 24px;
    margin-right: 10px;
    color: #223bb4;
  }
}
.offerWrap .offerBlock ul li:not(:last-child) {
  border-bottom: 1px dashed rgb(216, 216, 216);
}
@media (max-width: 991px) {
  .offerWrap .offerBlock .priceBlock {
    padding: 0 30px;
  }
}
.offerWrap .offerBlock .priceBlock .price {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 5px;
}
.offerWrap .offerBlock .priceBlock .btn {
  border: 1px solid #5BAE47;
  border-radius: 25px;
  height: 45px;
  padding: 0 25px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.offerWrap .offerBlock .priceBlock .btn:hover {
  background-color: #5BAE47;
  color: #fff;
}
.offerWrap .offerBlock:last-child {
  border-radius: 0 16px 16px 0;
  -webkit-border-radius: 0 16px 16px 0;
  -moz-border-radius: 0 16px 16px 0;
  -ms-border-radius: 0 16px 16px 0;
  -o-border-radius: 0 16px 16px 0;
}
.offerWrap .offerBlock.popular {
  background-color: #32c3eb;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  margin-top: -25px;
  margin-bottom: -25px;
  padding-top: 0;
  color: #fff;
}
@media (max-width: 991px) {
  .offerWrap .offerBlock.popular {
    margin: 30px 0;
  }
}
.offerWrap .offerBlock.popular ul li i {
  color: #fff;
}
@media (max-width: 991px) {
  .offerWrap .offerBlock.popular ul li div i {
    color: #000831;
  }
}
.offerWrap .offerBlock.popular ul li:not(:last-child) {
  border-bottom: 1px dashed #fff;
}
.offerWrap .offerBlock.popular .percentageBlock {
  background: rgba(0, 0, 0, 0.5);
}
.offerWrap .offerBlock.popular .priceBlock .btn {
  border: 1px solid #ffffff;
  color: #ffffff;
}
.offerWrap .offerBlock.popular .priceBlock .btn:hover {
  background-color: #ffffff;
  color: #0D3453;
}
.offerWrap .offerBlock .popular-badge {
  background: rgb(255, 171, 0);
  padding: 4px 16px;
  border-radius: 0px 0px 20px 20px;
  color: rgb(34, 34, 34);
  margin-bottom: 16px;
}
@media (max-width: 991px) {
  .offerWrap .offerBlock .popular-badge {
    margin-left: auto;
    margin-right: 30px;
  }
}

.postPropertyWrap * {
  font-family: "Abel", serif;
}

.postPropertyWrap {
  background-color: #ffffff;
  padding: 30px;
  border: 1px solid #ccf4ff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 24px 0px;
}
@media (max-width: 767px) {
  .postPropertyWrap {
    padding: 20px;
  }
}
.postPropertyWrap .postPropertyStatus {
  max-width: 300px;
  border: 1px solid #bef1ff;
  padding: 20px;
  border-radius: 5px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 50%, rgba(201, 243, 255, 0.8) 100%);
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .postPropertyWrap .postPropertyStatus {
    max-width: 100%;
  }
}
.postPropertyWrap .postPropertyStatus .postPropertyStatusDetail {
  display: flex;
  flex-direction: column;
}
.postPropertyWrap .postPropertyStatus .btn-back {
  font-size: 16px;
  margin-bottom: 15px;
  color: #000;
  text-decoration: none;
}
.postPropertyWrap .postPropertyStatus .btn-back i {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
}
.postPropertyWrap .postPropertyStatus .btn-back:hover {
  color: #0D3453;
}
.postPropertyWrap .postPropertyStatus .btn-back:hover i {
  transform: translateX(-5px);
  -webkit-transform: translateX(-5px);
  -moz-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  -o-transform: translateX(-5px);
}
.postPropertyWrap .postPropertyStatus h4 {
  font-weight: 600;
  margin: 0;
}
.postPropertyWrap .postPropertyStatus p {
  margin: 0 0 5px;
  font-size: 18px;
  color: #000000;
  text-align: left;
}
.postPropertyWrap .postPropertyStatus .progress {
  width: 100%;
  height: 10px;
  background: #fff;
}
.postPropertyWrap .postPropertyStatus .progress .progress-bar {
  background-color: #5BAE47;
}
.postPropertyWrap .postPropertyStatus ul {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
}
.postPropertyWrap .postPropertyStatus ul li {
  display: flex;
  position: relative;
}
.postPropertyWrap .postPropertyStatus ul li h5 {
  margin: 0 0 4px;
  font-weight: 600;
  cursor: pointer;
}
.postPropertyWrap .postPropertyStatus ul li i {
  width: 30px;
  height: 30px;
  background-color: rgba(15, 154, 181, 0.231372549);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.postPropertyWrap .postPropertyStatus ul li span {
  padding: 3px 15px;
  font-size: 14px;
  border-radius: 25px;
  font-weight: 600;
  border: 1px solid lightgray;
  background: transparent;
  color: #868686;
}
.postPropertyWrap .postPropertyStatus ul li:not(:last-child) {
  padding-bottom: 15px;
}
.postPropertyWrap .postPropertyStatus ul li:not(:last-child)::before {
  content: "";
  position: absolute;
  left: 14px;
  top: 33px;
  background: #bce6ee;
  width: 2px;
  height: calc(100% - 36px);
}
.postPropertyWrap .postPropertyStatus ul li.inProgress i {
  background-color: #2039b4;
  border: 2px solid #2039b4;
  box-shadow: 0px 0px 0px 2px #fff inset;
}
.postPropertyWrap .postPropertyStatus ul li.inProgress span {
  background-color: #aff3ff;
  color: #207f91;
  border: 1px solid #aff3ff;
}
.postPropertyWrap .postPropertyStatus ul li.inProgress::before {
  background: #2039b4;
}
.postPropertyWrap .postPropertyStatus ul li.completed i {
  background-color: #5BAE47;
  border: 2px solid #5BAE47;
  color: #fff;
  font-size: 26px;
}
.postPropertyWrap .postPropertyStatus ul li.completed i::before {
  content: "\f26e";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
}
.postPropertyWrap .postPropertyStatus ul li.completed span {
  background-color: #d4ffca;
  color: #389721;
  border: 1px solid #d4ffca;
}
.postPropertyWrap .postPropertyStatus ul li.completed::before {
  background: #5BAE47;
}
.postPropertyWrap .postPropertyStatus .quickpost {
  background: #e6f8ff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 40px;
  font-size: 18px;
}
.postPropertyWrap .postPropertyStatus .quickpost a {
  color: #5BAE47;
  font-weight: 500;
}
.postPropertyWrap .postPropertyStatus .quickpost a:hover {
  text-decoration: none;
  color: #0D3453;
}
.postPropertyWrap .postPropertyForm {
  padding: 50px;
  border: 1px solid #ddd;
  border-radius: 7px;
  height: 100%;
}
@media (max-width: 767px) {
  .postPropertyWrap .postPropertyForm {
    padding: 20px;
  }
}
.postPropertyWrap .postPropertyForm .title-link {
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.postPropertyWrap .postPropertyForm .title-link i {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
}
.postPropertyWrap .postPropertyForm .title-link:hover {
  color: #0D3453;
}
.postPropertyWrap .postPropertyForm .title-link:hover i {
  transform: translateX(-5px);
  -webkit-transform: translateX(-5px);
  -moz-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  -o-transform: translateX(-5px);
}
.postPropertyWrap .postPropertyForm .form-label {
  font-size: 20px;
}
.postPropertyWrap .postPropertyForm .custom-check .form-check-label {
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
}
.postPropertyWrap .postPropertyForm .custom-check .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.postPropertyWrap .postPropertyForm .custom-check .form-check-input:checked {
  background-color: #5BAE47;
  border-color: #5BAE47;
}
.postPropertyWrap .postPropertyForm .form-control {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-width: 0 0 1px;
  font-size: 18px;
}
.postPropertyWrap .postPropertyForm .form-control.form-search {
  background-image: url(../images/search.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: left 0px center;
  padding-left: 34px;
}
.postPropertyWrap .postPropertyForm .form-control.form-date {
  background: #fff url(../images/calendar.svg) no-repeat right 10px center;
  padding-right: 40px;
}
.postPropertyWrap .postPropertyForm .form-control.form-rupee {
  background: #fff url(../images/currency-rupee.svg) no-repeat left 10px center;
  padding-left: 40px;
}
.postPropertyWrap .postPropertyForm .form-control:focus, .postPropertyWrap .postPropertyForm .form-control:focus-visible {
  outline: none;
}
.postPropertyWrap .postPropertyForm i[class*=fa] {
  font-family: "Font Awesome 6 Free";
}
.postPropertyWrap .postPropertyForm .highlight {
  margin-top: 15px;
  border-radius: 64px;
  background: #fff6e3;
  border: 1px solid #f8b018;
  display: flex;
  align-items: center;
  padding: 11px 20px;
  font-size: 18px;
  line-height: 22px;
}
.postPropertyWrap .postPropertyForm .highlight i {
  font-size: 35px;
  margin-right: 15px;
  color: #f8b018;
}
.postPropertyWrap .postPropertyForm p {
  text-align: inherit;
}
.postPropertyWrap .postPropertyForm .uploadwrap {
  border: 1px dashed #666;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: relative;
}
.postPropertyWrap .postPropertyForm .uploadwrap input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  z-index: 1;
}
.postPropertyWrap .postPropertyForm .uploadwrap i {
  font-size: 30px;
}
.postPropertyWrap .postPropertyForm .uploadwrap span {
  font-size: 18px;
  font-weight: 600;
}
.postPropertyWrap .btn-big-green {
  width: 100%;
  background-color: #5BAE47;
  height: 60px;
  font-size: 20px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.postPropertyWrap .btn-big-green:hover {
  background-color: #539d41;
}

.rectBox {
  padding: 0;
}
.rectBox .form-check-label {
  border: 1px solid #dbdbdb;
  padding: 0 30px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  height: 60px;
  font-size: 18px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.rectBox .form-check-input {
  display: none;
}
.rectBox .form-check-input:checked + .form-check-label {
  background-color: #A4F491;
  border-color: #8ed97c;
  color: #406f08;
}
.rectBox.icon-block .form-check-label {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 150px;
  text-align: center;
  line-height: 21px;
  height: 100%;
}
.rectBox.icon-block .form-check-label.w-135 {
  width: 135px;
}
.rectBox.icon-block .form-check-label i {
  height: 50px;
  margin-bottom: 10px;
  font-size: 30px;
  display: flex;
  align-items: end;
}
.rectBox.icon-block .form-check-label i img {
  height: 100%;
}
.rectBox.icon-block .form-check-label span {
  flex: 1;
}

.quill .ql-toolbar {
  border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  -ms-border-radius: 6px 6px 0 0;
  -o-border-radius: 6px 6px 0 0;
}
.quill .ql-container {
  min-height: 200px;
  border-radius: 0 0 6px 6px;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  -ms-border-radius: 0 0 6px 6px;
  -o-border-radius: 0 0 6px 6px;
  display: flex;
  flex-direction: column;
}
.quill .ql-container .ql-editor {
  flex: 1;
}
.quill .ql-container .ql-editor p {
  text-align: left;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Jost", sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body[style="overflow: hidden; padding-right: 0px;"] header .headerTop .navbar-toggler .navbar-toggler-icon::before {
  transform: translateY(8px) rotate(135deg);
  -moz-transform: translateY(8px) rotate(135deg);
  -webkit-transform: translateY(8px) rotate(135deg);
  width: 20px;
  height: 3px;
  -ms-transform: translateY(8px) rotate(135deg);
  -o-transform: translateY(8px) rotate(135deg);
}
body[style="overflow: hidden; padding-right: 0px;"] header .headerTop .navbar-toggler .navbar-toggler-icon::after {
  transform: translateY(-7px) rotate(-135deg);
  -moz-transform: translateY(-7px) rotate(-135deg);
  -webkit-transform: translateY(-7px) rotate(-135deg);
  width: 20px;
  height: 3px;
}
body[style="overflow: hidden; padding-right: 0px;"] header .headerTop .navbar-toggler .navbar-toggler-icon b {
  transform: scale(0);
}
body.modal-open header .headerTop .offcanvas.show {
  display: none;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.font-7 {
  font-size: 7px !important;
}
.font-8 {
  font-size: 8px !important;
}
.font-9 {
  font-size: 9px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
  line-height: 20px;
}
.font-17 {
  font-size: 17px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-34 {
  font-size: 34px;
}
.font-35 {
  font-size: 35px;
}
.font-36 {
  font-size: 36px;
  line-height: 36px;
}
.font-100 {
  font-weight: 100;
}
.font-200 {
  font-weight: 200;
}
.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-style: normal;
  font-weight: 500;
}
.font-600 {
  font-style: normal;
  font-weight: 600;
}
.font-700 {
  font-style: normal;
  font-weight: 700;
}
.font-900 {
  font-style: normal;
  font-weight: 900;
}

.w-2300 {
  width: 2300px;
}
.w-2000 {
  width: 2000px;
}
.w-1210 {
  max-width: 1210px;
  width: 94%;
}
.w-1200 {
  max-width: 1200px;
  width: 94%;
}
.w-1160 {
  max-width: 1160px;
  width: 94%;
}
.w-1080 {
  max-width: 1080px;
  width: 94%;
}
.w-1000 {
  max-width: 1000px;
  width: 94%;
}
.w-980 {
  max-width: 980px;
  width: 94%;
}
.w-750 {
  max-width: 750px;
  width: 94%;
}
.w-720 {
  max-width: 720px;
  width: 94%;
}

.max-1780 {
  max-width: 1780px;
  width: 96%;
}
.max-1366 {
  max-width: 1366px;
  width: 94%;
}
.max-1210 {
  max-width: 1210px;
  width: 94%;
}
.max-1140 {
  max-width: 1140px;
  width: 94%;
}
.max-1040 {
  max-width: 1040px;
  width: 94%;
}
.max-1016 {
  max-width: 1016px;
  width: 94%;
}
.max-1010 {
  max-width: 1010px;
  width: 94%;
}
.max-950 {
  max-width: 950px;
}
.max-940 {
  max-width: 940px;
  width: 94%;
}
.max-900 {
  max-width: 900px;
  width: 94%;
}
.max-850 {
  max-width: 850px;
  width: 94%;
}
.max-800 {
  max-width: 800px;
  width: 94%;
}
.max-740 {
  max-width: 740px;
  width: 90%;
}
.max-780 {
  max-width: 780px;
  width: 90%;
}
.max-700 {
  max-width: 700px !important;
  width: 90%;
}
.max-600 {
  max-width: 600px;
  width: 90%;
}
.max-620 {
  max-width: 620px;
  width: 90%;
}
.max-630 {
  max-width: 630px;
  width: 90%;
}
.max-540 {
  max-width: 540px;
  width: 90%;
}
.max-570 {
  max-width: 570px;
  width: 90%;
}
.max-360 {
  max-width: 360px;
  width: 90%;
}

.bg_darkblue {
  background: #0D3453;
}
.bg_gold {
  background: #F8B018;
}
.bg_grdBlueWhite {
  background: transparent linear-gradient(0deg, #fff 50%, #d1f1ff 100%) 0 0;
}
.bg_grdGold {
  background: transparent linear-gradient(255deg, #FFFFFF 0%, #FFF9EC 100%) 0% 0% no-repeat padding-box;
}
.bg-gray {
  background: #f8f8f8;
}
.bg-gray-f3 {
  background: #f3f3f3;
}
.bg-yellow {
  background-color: #FBB217;
}
.bg-litebeige {
  background-color: #FFF9EB;
}
.bg-gray-ed {
  background-color: #EDEFF0;
}
.bg-gray-f5 {
  background-color: #F5F6F8;
}

.text-darkblue {
  color: #0D3453;
}
.text-gray06 {
  color: rgba(102, 102, 102, 0.6);
}
.text-gray3 {
  color: #333;
}
.text-gray-8b {
  color: #8b8b8b;
}
.text-litered {
  color: #e86767;
}
.text-mint-hard-green {
  color: #0e657f;
}
.text-blue {
  color: #3041da;
}
.text-green {
  color: #0c7871;
}

.height-45 {
  height: 45px;
}
.height-50 {
  height: 50px;
}

.radius-5 {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}
.radius-10 {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
.radius-15 {
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}
.radius-20 {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}
.radius-25 {
  border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  -o-border-radius: 25px !important;
}

.top-5 {
  top: 5px;
}
.top-10 {
  top: 10px;
}
.top-15 {
  top: 15px;
}
.top-20 {
  top: 20px;
}
.top-25 {
  top: 25px;
}

.right-5 {
  right: 5px;
}
.right-10 {
  right: 10px;
}
.right-15 {
  right: 15px;
}
.right-20 {
  right: 20px;
}
.right-25 {
  right: 25px;
}

.btn-red {
  background: #d00000;
  border-radius: 25px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
.btn-red:hover {
  color: #fff;
  background-color: #9e0101;
}
.btn-red-border {
  background: transparent;
  border: 1px solid #d00000;
  border-radius: 25px;
  color: #d00000;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
.btn-red-border:hover {
  color: #fff;
  background-color: #d00000;
}
.btn-addmore {
  background: #aff3ff;
  border-radius: 25px;
  border: 1px solid #8ddae8;
  transition: all 0.3s ease-in-out;
}
.btn-addmore:hover {
  background-color: #15879b;
  border-color: #15879b;
  color: #fff;
}
.btn-edit {
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 8px 20px;
  transition: all 0.3s ease-in-out;
}
.btn-edit:hover {
  background-color: #0B3454;
  border-color: #0B3454;
  color: #fff;
}
.btn-border-gray {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  padding: 0 25px;
  line-height: 32px;
  border: 2px solid #7d7d7d;
  height: 35px;
  display: inline-flex;
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.btn-border-gray:hover {
  background-color: #0D3453;
  border-color: #0D3453;
  color: #fff;
}

.headertop {
  background-color: #0D3453;
}
.headertop .btn-click {
  background-color: #5BAE47;
  border: none;
  padding: 6px 20px;
  border-radius: 25px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.headertop .btn-click:hover {
  background-color: #407a32;
}
@media (max-width: 575px) {
  .headertop button {
    width: auto;
  }
}

header {
  z-index: 2;
  overflow: hidden;
}
header .btn-login {
  height: 40px;
  background: #f8b018;
  border-radius: 25px;
  padding: 0 30px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
header .btn-login:hover {
  background-color: #d28200;
  color: #fff;
}
header .btn-register {
  height: 40px;
  background: #32c3eb;
  border-radius: 25px;
  padding: 0 30px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
header .btn-register:hover {
  background-color: #0D3453;
  color: #fff;
}
header:hover {
  overflow: visible;
}
header .headerTop {
  background: #fff;
  position: relative;
  padding: 15px 0 20px;
}
header .headerTop::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 5px;
  background: transparent linear-gradient(270deg, #F8B020 0%, #F8B020 20%, #EF5A2A 40%, #D42941 60%, #27A7DF 80%, #51499C 100%);
}
header .headerTop .mobile-new-header {
  background: transparent linear-gradient(127deg, #0B3454 0%, #356184 100%);
  position: relative;
  padding: 15px;
}
header .headerTop .mobile-new-header .navbar-brand img {
  max-width: 150px;
}
header .headerTop .navbar-toggler {
  border: 0;
}
header .headerTop .navbar-toggler:focus {
  box-shadow: none;
  border: 0;
}
header .headerTop .navbar-toggler .navbar-toggler-icon {
  font-size: 0;
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 18px;
  justify-content: space-between;
  align-items: flex-end;
  background: 0 0;
  border: 0;
}
header .headerTop .navbar-toggler .navbar-toggler-icon::before, header .headerTop .navbar-toggler .navbar-toggler-icon::after {
  content: "";
  width: 100%;
  height: 2px;
  display: inline-block;
  background: #000000;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
header .headerTop .navbar-toggler .navbar-toggler-icon b {
  width: 100%;
  height: 2px;
  background: #000000;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
header .headerTop .navbar-toggler .navbar-toggler-icon ::before {
  transition: all 0.3s ease-in-out;
}
@media (min-width: 1200px) {
  header .headerTop .nav-item {
    position: static;
    display: flex;
    justify-content: center;
  }
  header .headerTop .nav-item:not(:last-child) {
    margin-right: 18px;
  }
  header .headerTop .nav-item .nav-link {
    color: #0D3453;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }
  header .headerTop .nav-item .nav-link.dropdown-toggle::after {
    width: 9px;
    height: 9px;
    border-width: 0 0 2px 2px;
    border-style: solid;
    border-color: transparent transparent #000000 #000000;
    margin-left: 12px;
    margin-top: 8px;
    vertical-align: top;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }
  header .headerTop .nav-item .nav-link.active, header .headerTop .nav-item .nav-link:hover {
    color: #5BAE47;
  }
  header .headerTop .nav-item .nav-link.active::after, header .headerTop .nav-item .nav-link:hover::after {
    border-color: transparent transparent #5BAE47 #5BAE47;
  }
  header .headerTop .nav-item.dropdown .dropdown-list {
    position: absolute;
    top: 150%;
    display: block;
    z-index: -1;
    opacity: 0;
    padding-top: 5px;
    height: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    border-top: 4px solid #5BAE47;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1019607843);
    border-radius: 4px;
    padding: 20px;
  }
  header .headerTop .nav-item.dropdown .dropdown-list::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #5BAE47;
    margin: auto;
    top: -9px;
  }
  header .headerTop .nav-item.dropdown .dropdown-list ul {
    list-style: none;
    text-align: left;
    position: relative;
    padding: 0;
  }
  header .headerTop .nav-item.dropdown .dropdown-list li:not(:last-child) {
    margin-bottom: 15px;
  }
  header .headerTop .nav-item.dropdown .dropdown-list li .dropdown-item {
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }
  header .headerTop .nav-item.dropdown .dropdown-list li .dropdown-item:hover {
    color: #5BAE47;
  }
  header .headerTop .nav-item.dropdown:hover .dropdown-list {
    top: 80px;
    z-index: 0;
    opacity: 1;
    height: auto;
  }
}
@media (max-width: 1199px) {
  header .headerTop .offcanvas.show:not(.hiding) {
    overflow: auto;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  header .headerTop .offcanvas.show:not(.hiding)::-webkit-scrollbar {
    width: 2px;
  }
  header .headerTop .offcanvas.show:not(.hiding)::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  header .headerTop .offcanvas.show:not(.hiding)::-webkit-scrollbar-thumb {
    background: #888;
  }
  header .headerTop .offcanvas.show:not(.hiding)::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  header .headerTop .nav-item {
    border-bottom: 1px solid #c9c9c9;
  }
  header .headerTop .nav-item .nav-link {
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  header .headerTop .nav-item .nav-link.dropdown-toggle::after {
    width: 9px;
    height: 9px;
    border-width: 0 0 2px 2px;
    border-style: solid;
    border-color: transparent transparent #000000 #000000;
    margin-left: 12px;
    vertical-align: top;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }
  header .headerTop .nav-item .nav-link.active, header .headerTop .nav-item .nav-link:hover {
    color: #5BAE47;
  }
  header .headerTop .nav-item .nav-link.active::after, header .headerTop .nav-item .nav-link:hover::after {
    border-color: transparent transparent #5BAE47 #5BAE47;
  }
  header .headerTop .nav-item ul {
    padding: 0;
    background: #5BAE47;
    border-bottom: 1px solid #489935;
  }
  header .headerTop .nav-item ul li:not(:last-child) {
    border-bottom: 1px solid #489935;
  }
  header .headerTop .nav-item ul li .dropdown-item {
    padding: 15px 20px;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
  }
  header .headerTop .nav-item ul li .dropdown-item:hover {
    background-color: #489935;
  }
}
@media (max-width: 575px) {
  header .headerTop .navbar-brand img {
    max-width: 120px;
  }
  header .headerTop .carousel-inner {
    max-width: 280px;
    margin: auto;
    width: 80%;
  }
  header .headerTop button {
    width: auto;
  }
}

main {
  z-index: 1;
  position: relative;
  flex: 1;
}
main .custom_dark_banner {
  background: transparent linear-gradient(127deg, #001424 0%, #356184 100%);
}
main .custom_dark_banner h1 {
  color: #FFFFFF;
  font-size: 48px;
  line-height: 55px;
}
main .alumni_impact_banner {
  background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.xyz/images/alumni-impact-globe.svg) no-repeat bottom center/cover;
  min-height: 680px;
}
@media (max-width: 1300px) {
  main .alumni_impact_banner {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.xyz/images/alumni-impact-globe.svg) no-repeat bottom right/auto 100%;
  }
}
main .career_guidance_program_banner {
  background: transparent linear-gradient(256deg, #FFEAE2 0%, #FFF8F5 33%, #FDFDFF 53%, #FFEFEA 100%);
}
main .client_banner {
  background: transparent linear-gradient(258deg, #BDDDF6 0%, #FFF8F5 33%, #FDFDFF 53%, #C4E0F6 100%);
}
main .client_banner h1 {
  color: #000000;
}
main .client_banner address ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
main .client_banner address ul li {
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 0 40px;
}
main .client_banner address ul li.mobile {
  background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.xyz/images/phone_icon.svg) left 3px/22px auto no-repeat;
}
main .client_banner address ul li.mail {
  background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.xyz/images/email_icon.svg) left 8px/22px auto no-repeat;
}
main .client_banner address ul li.location {
  background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.xyz/images/map_icon.svg) left 1px/22px auto no-repeat;
}
main .client_banner address ul li:not(:last-child) {
  margin-bottom: 20px;
}
main .client_banner address ul li a {
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
main .client_banner address ul li a:hover {
  color: #5BAE47;
}
main .bannerSlider.owl-theme .owl-nav {
  margin: 0;
}
main .bannerSlider.owl-theme .owl-nav button {
  position: absolute;
  width: 22px;
  height: 40px;
  background-color: transparent;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 0 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27white%27 stroke=%27%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271%27 d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left -12px center;
  background-size: 45px;
  top: 0;
  bottom: 0;
  margin: auto;
}
main .bannerSlider.owl-theme .owl-nav button::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
}
main .bannerSlider.owl-theme .owl-nav button.owl-prev {
  left: -60px;
}
main .bannerSlider.owl-theme .owl-nav button.owl-next {
  right: -60px;
  transform: scale(-1);
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  -o-transform: scale(-1);
}
main .bannerSlider.owl-theme .owl-nav button:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27white%27 stroke=%27%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271%27 d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left -12px center;
  background-size: 45px;
  background-color: transparent;
}
main .FeaturedListSlider.owl-theme .owl-nav button {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 0 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 18px;
  background-color: #fff;
  top: 0;
  bottom: 0;
  margin: auto;
}
main .FeaturedListSlider.owl-theme .owl-nav button::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
}
main .FeaturedListSlider.owl-theme .owl-nav button.owl-prev {
  left: -60px;
}
main .FeaturedListSlider.owl-theme .owl-nav button.owl-next {
  right: -60px;
  transform: scale(-1);
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  -o-transform: scale(-1);
}
main .FeaturedListSlider.owl-theme .owl-nav button:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 18px;
  background-color: #000;
}
main .FeatureListBlock {
  background-color: #ffffff;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}
main .FeatureListBlock .FeatureListBlockImg {
  width: 80px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
}
main .FeatureListBlock .FeatureListBlockBrife {
  width: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
main .FeatureListBlock .FeatureListBlockBrife h5 {
  font-size: 18px;
  line-height: 18px;
  margin: 0;
}
main .FeatureListBlock .FeatureListBlockBrife h5 a {
  text-decoration: none;
  line-height: 18px;
  color: #0D3453;
}
main .FeatureListBlock .FeatureListBlockBrife p {
  margin: 0;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #686868;
}
main .custom_search_wrap {
  max-width: 1040px;
  width: 90%;
}
main .custom_search_tab {
  display: flex;
  justify-content: center;
  max-width: 470px;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0px 0;
  border-bottom: none;
  flex-wrap: wrap;
}
main .custom_search_tab a {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  -webkit-text-decoration: navajowhite;
          text-decoration: navajowhite;
  font-weight: 500;
  color: #666;
}
main .custom_search_tab a:not(:last-child) {
  border-right: 1px solid #ccc;
}
main .custom_search_form {
  border: 1px solid #ccc;
  border-radius: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
}
main .custom_search_form .form-select {
  width: 180px;
  height: 50px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
main .select_input {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
}
@media (max-width: 575px) {
  main .select_input {
    flex-direction: column;
  }
}
main .select_input .form-select {
  max-width: 180px;
  border: none;
  border-radius: 0;
  height: 60px;
}
@media (max-width: 575px) {
  main .select_input .form-select {
    border-bottom: 1px solid #ccc;
    max-width: 100%;
    width: 100%;
  }
}
main .select_input .input-group {
  flex: 1;
}
main .select_input .input-group .form-control {
  height: 60px;
  border-width: 0 1px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
main .select_input .input-group .btn-submit {
  width: 120px;
  background: #e24335;
  color: #fff;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
main .about_banner {
  padding: 100px 0;
  background: transparent linear-gradient(256deg, #FFF3DB 0%, #FFF7E5 33%, #FFFFFF 53%, #FFEBEE 100%);
}
@media (max-width: 767px) {
  main .about_banner {
    padding: 50px 0;
  }
}
main .about_banner h1 {
  color: #000000;
}
main .headerBanner {
  overflow: hidden;
}
main .headerBanner h1 {
  font-weight: 600;
  letter-spacing: -1.2px;
  margin-bottom: 30px;
}
main .headerBanner .green-tick ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
main .headerBanner .green-tick ul li {
  position: relative;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.55px;
  color: #FFFFFF;
  padding-left: 27px;
}
main .headerBanner .green-tick ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 16px;
  height: 16px;
  background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.xyz/images/tick-inside-circle.svg) no-repeat 0 0/100%;
}
main .headerBanner .green-tick ul li:not(:last-child) {
  margin-bottom: 10px;
}
main .headerBanner .horizontal-scroll {
  overflow-x: auto;
  overflow-y: visible;
  scrollbar-width: thin;
}
main .headerBanner .horizontal-scroll::-webkit-scrollbar {
  height: 4px;
}
main .headerBanner .horizontal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
main .headerBanner .horizontal-scroll::-webkit-scrollbar-thumb {
  background: #888;
}
main .headerBanner .horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
main .headerBanner .horizontal-scroll .banner-white-block-wrap {
  list-style: none;
  display: flex;
  padding: 0 0 10px;
  margin: 0;
  width: -moz-max-content;
  width: max-content;
  min-width: 100%;
  padding-bottom: 10px;
}
main .headerBanner .horizontal-scroll .banner-white-block-wrap li {
  flex: 1;
}
main .headerBanner .horizontal-scroll .banner-white-block-wrap li:not(:last-child) {
  margin-right: 20px;
}
main .headerBanner .banner-white-block {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(13, 52, 83, 0.1019607843);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
}
@media (max-width: 800px) {
  main .headerBanner .banner-white-block {
    justify-content: start;
  }
}
main .headerBanner .banner-white-block .blockBrife {
  color: #0D3453;
}
main .headerBanner .banner-white-block .blockBrife .number {
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
main .headerBanner .banner-white-block .blockBrife .number span.plus {
  font-size: 24px;
  font-weight: 400;
  margin-left: 5px;
}
main .headerBanner .banner-white-block .blockBrife .title {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}
main .headerBanner h1 {
  font-size: 45px;
  line-height: 50px;
}
main .headerBanner h1 span {
  font-size: 80px;
}
@media (max-width: 575px) {
  main .headerBanner h1 {
    font-size: 35px;
    line-height: 38px;
  }
  main .headerBanner h1 span {
    font-size: 50px;
  }
}
main .headerBanner h4 {
  font-size: 30px;
}
main .headerBanner .btn-bookdemo {
  position: relative;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
  width: 164px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
main .headerBanner .btn-bookdemo.grdGreenBlue .icon-play, main .headerBanner .btn-bookdemo.grdOrangeGold .icon-play {
  position: relative;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-right: 5px;
}
main .headerBanner .btn-bookdemo.grdGreenBlue .icon-play::before, main .headerBanner .btn-bookdemo.grdOrangeGold .icon-play::before {
  content: "";
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0px;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid #5BAE47;
}
main .headerBanner .btn-bookdemo.grdGreenBlue:hover .icon-play::before, main .headerBanner .btn-bookdemo.grdOrangeGold:hover .icon-play::before {
  border-left: 5px solid #29A7D6;
}
main .headerBanner .btn-bookdemo.grdGreenBlue::before, main .headerBanner .btn-bookdemo.grdOrangeGold::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110%;
  height: 400%;
  background: transparent linear-gradient(271deg, #26A7DF 0%, #5BAE47 100%);
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.5s ease-in-out;
}
main .headerBanner .btn-bookdemo.grdOrangeGold {
  width: 196px;
}
main .headerBanner .btn-bookdemo.grdOrangeGold::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110%;
  height: 400%;
  background: transparent linear-gradient(271deg, #F05A2A 0%, #F8B018 100%);
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.5s ease-in-out;
}
main .headerBanner .btn-bookdemo.grdBlueLavender::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 600%;
  background: transparent linear-gradient(271deg, #51499C 0%, #26A7DF 100%);
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.5s ease-in-out;
}
main .headerBanner .btn-bookdemo:hover::before {
  transform: translate3d(-50%, -50%, 0) rotate(-180deg);
}
main .headerBanner .btn-bookdemo span {
  position: relative;
  padding-top: 2px;
}
main h2 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 767px) {
  main h2 {
    font-size: 30px;
  }
}
main p {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #0D3453;
  text-align: center;
  margin-bottom: 35px;
}
main ul.tick {
  list-style: none;
  margin: 0 0 20px;
}
main ul.tick li {
  font-size: 18px;
  position: relative;
}
main ul.tick li::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 12px;
  background: url(../images/check-mark.svg) no-repeat;
  left: -27px;
  top: 7px;
}
main ul.tick ol li::before {
  display: none;
}
main .coreServices {
  padding: 70px 0 100px;
}
@media (max-width: 767px) {
  main .coreServices {
    padding: 50px 0;
  }
}
main .coreServices .coreServicesBlock {
  border: 1px solid rgba(13, 52, 83, 0.1019607843);
  border-radius: 15px;
  opacity: 1;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
main .coreServices .coreServicesBlock ul {
  padding: 0;
  margin: 0;
  list-style: none;
  border-top: 1px solid rgba(113, 86, 74, 0.1882352941);
  overflow: hidden;
  height: 0;
  opacity: 0;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
main .coreServices .coreServicesBlock li {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
main .coreServices .coreServicesBlock li.title {
  font-weight: 500;
  list-style: none;
  width: 100%;
}
main .coreServices .coreServicesBlock li.title::before {
  display: none;
}
main .coreServices .coreServicesBlock li::before {
  content: "•";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-top: 4px;
  vertical-align: top;
}
main .coreServices .coreServicesBlock.litePink {
  background: transparent linear-gradient(299deg, #f8dde1 0%, #FFFFFF 14%, #FFFFFF 100%);
}
main .coreServices .coreServicesBlock.litePink:hover {
  background: transparent linear-gradient(180deg, #fff9fa 0%, #f8dde1 100%);
}
main .coreServices .coreServicesBlock.litePink li::before {
  color: #6F5347;
}
main .coreServices .coreServicesBlock.litePink ul {
  color: #6F5347;
}
main .coreServices .coreServicesBlock.liteBlue {
  background: transparent linear-gradient(300deg, #ddf1fa 0%, #FFFFFF 14%, #FFFFFF 100%);
}
main .coreServices .coreServicesBlock.liteBlue:hover {
  background: transparent linear-gradient(180deg, #f1f6f8 0%, #ddf1fa 100%);
}
main .coreServices .coreServicesBlock.liteBlue li::before {
  color: #278ed3;
}
main .coreServices .coreServicesBlock.liteBlue ul {
  color: #278ed3;
}
main .coreServices .coreServicesBlock.liteOrgane {
  background: transparent linear-gradient(300deg, #fde4dd 0%, #FFFFFF 14%, #FFFFFF 100%);
}
main .coreServices .coreServicesBlock.liteOrgane:hover {
  background: transparent linear-gradient(180deg, #FFFBFA 0%, #FFEDE6 100%);
}
main .coreServices .coreServicesBlock.liteOrgane li::before {
  color: #f08061;
}
main .coreServices .coreServicesBlock.liteOrgane ul {
  color: #f08061;
}
main .coreServices .coreServicesBlock.liteGold {
  background: transparent linear-gradient(300deg, #fcf1d8 0%, #FFFFFF 14%, #FFFFFF 100%);
}
main .coreServices .coreServicesBlock.liteGold:hover {
  background: transparent linear-gradient(180deg, #faf8f2 0%, #fcf1d8 100%);
}
main .coreServices .coreServicesBlock.liteGold li::before {
  color: #634b13;
}
main .coreServices .coreServicesBlock.liteGold ul {
  color: #634b13;
}
main .coreServices .coreServicesBlock.liteGreen {
  background: transparent linear-gradient(300deg, #e5f2e1 0%, #FFFFFF 14%, #FFFFFF 100%);
}
main .coreServices .coreServicesBlock.liteGreen:hover {
  background: transparent linear-gradient(180deg, #f1f7ef 0%, #e5f2e1 100%);
}
main .coreServices .coreServicesBlock.liteGreen li::before {
  color: #354e2d;
}
main .coreServices .coreServicesBlock.liteGreen ul {
  color: #354e2d;
}
main .coreServices .coreServicesBlock.liteLavender {
  background: transparent linear-gradient(300deg, #deddec 0%, #FFFFFF 14%, #FFFFFF 100%);
}
main .coreServices .coreServicesBlock.liteLavender:hover {
  background: transparent linear-gradient(180deg, #f5f5ff 0%, #deddec 100%);
}
main .coreServices .coreServicesBlock.liteLavender li::before {
  color: #7e79c7;
}
main .coreServices .coreServicesBlock.liteLavender ul {
  color: #7e79c7;
}
main .coreServices .coreServicesBlock .icon {
  margin-bottom: 10px;
}
main .coreServices .coreServicesBlock h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #0D3453;
}
main .coreServices .coreServicesBlock p {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #0D3453;
  margin-bottom: 0;
}
main .coreServices .coreServicesBlock .btn-readmore {
  position: absolute;
  bottom: 0;
  top: 0;
  background: url(../images/down-arrow.svg) no-repeat right 10px bottom 10px;
  width: 100%;
  height: 100%;
}
main .coreServices .coreServicesBlock:hover {
  z-index: 1;
  box-shadow: 0px 0px 50px #FDEDE8;
}
main .coreServices .coreServicesBlock:hover h3 {
  font-size: 16px;
}
main .coreServices .coreServicesBlock:hover p {
  font-size: 14px;
}
main .coreServices .coreServicesBlock:hover Ul {
  padding: 17px 0 0 0;
  margin: 20px 0 0;
  height: auto;
  opacity: 1;
  font-size: 14px;
}
main .coreServices .coreServicesBlock:hover .btn-readmore {
  background: none;
}
main .partners {
  padding: 50px 0;
  background: #F7F9FA;
}
@media (max-width: 767px) {
  main .partners {
    padding: 50px 0;
  }
}
main .partners .partnersBlock {
  background: #FFFFFF;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  opacity: 1;
  overflow: hidden;
}
main .partners .partnersBlock .partnersBlockImg {
  overflow: hidden;
  position: relative;
}
main .partners .partnersBlock .partnersBlockImg > .btn-play {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: transparent !important;
  z-index: 9;
}
main .partners .partnersBlock .partnersBlockImg img {
  transition: all 0.3s ease-in-out;
}
main .partners .partnersBlock .partnersBlockTitle {
  padding: 24px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}
main .partners .partnersBlock .partnersBlockTitle .TitleDetail {
  width: calc(100% - 50px);
  padding-right: 30px;
}
main .partners .partnersBlock .partnersBlockTitle .TitleDetail h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #0D3453;
}
main .partners .partnersBlock .partnersBlockTitle .TitleDetail p {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #0D3453;
  margin: 0;
  text-align: left;
}
main .partners .partnersBlock .partnersBlockTitle .btn-play {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease-in-out;
}
main .partners .partnersBlock .partnersBlockTitle .btn-play::before {
  content: "";
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 12px solid #ffffff;
  left: 21px;
  top: 17px;
  width: 0;
  height: 0;
}
main .partners .partnersBlock.schools .btn-play {
  background: #26a7df;
}
main .partners .partnersBlock.schools .btn-play:hover {
  background: #135470;
}
main .partners .partnersBlock.universities .btn-play {
  background: #51499C;
}
main .partners .partnersBlock.universities .btn-play:hover {
  background: #29254e;
}
main .partners .partnersBlock.counsellors .btn-play {
  background: #f05a2a;
}
main .partners .partnersBlock.counsellors .btn-play:hover {
  background: #782d15;
}
main .partners .partnersBlock:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
main .insights {
  padding: 50px 0;
}
@media (max-width: 767px) {
  main .insights {
    padding: 50px 0;
  }
}
main .insights .insightsSlider .insightBlock {
  background: #FFFFFF;
  border: 1px solid rgba(13, 52, 83, 0.1019607843);
  border-radius: 15px;
}
main .insights .insightsSlider .insightBlockImg {
  vertical-align: top;
  position: relative;
}
main .insights .insightsSlider .insightBlockImg a {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
main .insights .insightsSlider .insightBlockImg .badge {
  position: absolute;
  z-index: 4;
  top: 20px;
  right: 20px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 17px;
}
main .insights .insightsSlider .insightBlockImg .badge.school-marketing {
  background: #26a7df;
}
main .insights .insightsSlider .insightBlockImg .badge.alumni-impact {
  background: #d42941;
}
main .insights .insightsSlider .insightBlockBrife {
  padding: 24px 30px;
}
main .insights .insightsSlider .insightBlockBrife h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  color: #0D3453;
}
main .insights .insightsSlider .insightBlockBrife h5 a {
  color: #0D3453;
  text-decoration: none;
}
main .insights .insightsSlider .insightBlockBrife p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: #0D3453;
  opacity: 0.7;
  margin: 0;
  text-align: left;
}
main .insights .insightsSlider.owl-theme .owl-nav button {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='black' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  left: -70px;
  opacity: 1;
  filter: alpha(opacity=100);
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  border: 1px solid rgba(13, 52, 83, 0.2);
  border-radius: 15px;
  font-size: 0;
}
main .insights .insightsSlider.owl-theme .owl-nav button.owl-next {
  left: auto;
  right: -70px;
  transform: scale(-1);
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  -o-transform: scale(-1);
}
main .insights .insightsSlider.owl-theme .owl-nav button.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
@media (max-width: 1336px) {
  main .insights .insightsSlider.owl-theme .owl-nav button {
    left: -55px;
  }
  main .insights .insightsSlider.owl-theme .owl-nav button.owl-next {
    right: -55px;
  }
}
main .insights .btn-insights {
  border: 1px solid #0D3453;
  border-radius: 23px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: #0D3453;
  text-transform: uppercase;
  text-decoration: none;
  max-width: 426px;
  width: 96%;
  height: 45px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.3s ease-in-out;
}
main .insights .btn-insights:hover {
  background: #0D3453;
  color: #ffffff;
}
main .connect {
  padding: 100px 0;
  background: #5BAE47;
}
@media (max-width: 767px) {
  main .connect {
    padding: 50px 0;
  }
}
main .connect h2 {
  color: #ffffff;
  margin-bottom: 30px;
}
main .connect .form-control,
main .connect .form-select {
  max-width: 100%;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
main .connect .btn-submit {
  max-width: 350px;
  width: 90%;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
main .connect .btn-submit:hover {
  background-color: #ffffff;
  color: #5BAE47;
}
main .alumniImpactProgram {
  padding: 0 0 100px;
}
@media (max-width: 767px) {
  main .alumniImpactProgram {
    padding: 0 0 50px;
  }
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer {
  overflow: visible;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage {
  display: flex;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item {
  height: 100%;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock {
  background: #FFFFFF;
  border: 2px solid rgba(13, 52, 83, 0.1019607843);
  border-radius: 15px;
  opacity: 1;
  padding: 30px;
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1336px) {
  main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock {
    width: 300px;
  }
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock .icon {
  width: 48px;
  margin-bottom: 20px;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #0D3453;
  margin-bottom: 10px;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #0D3453;
  opacity: 0.8;
  text-align: left;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock a {
  margin-top: auto;
  margin-left: auto;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #5BAE47;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  padding: 0 15px 0 0;
  transition: all 0.3s ease-in-out;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock a::before {
  content: "";
  background: url(../images/down-arrow.svg) no-repeat 0 0/9px;
  width: 9px;
  height: 9px;
  position: absolute;
  bottom: 8px;
  right: 0;
  filter: invert(51%) sepia(91%) saturate(313%) hue-rotate(63deg) brightness(98%) contrast(86%);
  -webkit-filter: invert(51%) sepia(91%) saturate(313%) hue-rotate(63deg) brightness(98%) contrast(86%);
  transition: all 0.3s ease-in-out;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock a:hover {
  color: #0D3453;
}
main .alumniImpactProgram .owl-carousel .owl-stage-outer .owl-stage .item .alumniImpactProgramBlock a:hover::before {
  filter: invert(1) brightness(0);
  -webkit-filter: invert(1) brightness(0);
  right: -5px;
}
main .alumniImpactProgram .owl-carousel .owl-nav {
  display: flex;
}
main .alumniImpactProgram .owl-carousel .owl-nav button {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='black' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  left: -70px;
  opacity: 1;
  filter: alpha(opacity=100);
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  border: 1px solid rgba(13, 52, 83, 0.2);
  border-radius: 15px;
  font-size: 0;
}
main .alumniImpactProgram .owl-carousel .owl-nav button:hover {
  background-color: #ccc;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='black' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
}
main .alumniImpactProgram .owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: -70px;
  transform: scale(-1);
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  -o-transform: scale(-1);
}
main .alumniImpactProgram .owl-carousel .owl-nav button.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
@media (max-width: 1336px) {
  main .alumniImpactProgram .owl-carousel .owl-nav button {
    left: -55px;
  }
  main .alumniImpactProgram .owl-carousel .owl-nav button.owl-next {
    right: -55px;
  }
}
main .trustedCompanies {
  padding: 90px 0 100px;
  background-color: #F7F9FA;
}
@media (max-width: 767px) {
  main .trustedCompanies {
    padding: 50px 0;
  }
}
main .trustedCompanies .owl-carousel .owl-stage {
  display: flex;
}
main .trustedCompanies .owl-carousel .owl-stage .item {
  height: 100%;
}
main .trustedCompanies .owl-carousel .owl-stage .item .logo-block {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 100%;
  border: 1px solid #EAEAEA;
  border-radius: 15px;
  padding: 10px 20px;
}
main .trustedCompanies .owl-carousel .owl-stage .item .logo-block img {
  width: auto;
}
main .faq {
  padding: 100px 0;
}
main .faq h2 {
  text-align: left;
}
main .faq p {
  text-align: left;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  color: #000000;
}
main .faq p.text-darkblue {
  color: #0D3453;
}
main .faq .accordion-item {
  border-width: 0 0 1px;
  border-style: dashed;
  border-color: rgba(105, 125, 141, 0.5647058824);
}
main .faq .accordion-item .accordion-button {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  background: none;
  padding: 25px 0 25px 35px;
  border-bottom: none;
  box-shadow: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
main .faq .accordion-item .accordion-button:not([aria-expanded=false]) {
  padding: 25px 0 10px 35px;
  color: #000000;
}
main .faq .accordion-item .accordion-button:not([aria-expanded=false])::after {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
}
main .faq .accordion-item .accordion-button::after {
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  left: 0;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: invert(50%) sepia(90%) saturate(317%) hue-rotate(63deg) brightness(100%) contrast(86%);
  -webkit-filter: invert(50%) sepia(90%) saturate(317%) hue-rotate(63deg) brightness(100%) contrast(86%);
}
main .faq .accordion-item .accordion-body {
  padding: 0px 0 25px 35px;
}
main .faq .accordion-item .accordion-body p:not(:last-child) {
  margin-bottom: 15px;
}
main .tab-section {
  border-bottom: 1px solid #DFDFDF;
}
main .tab-section .w-1000 {
  max-width: 1000px;
  width: 94%;
}
main .tab-section .nav {
  justify-content: space-between;
}
@media (max-width: 767px) {
  main .tab-section .nav {
    overflow-x: auto;
    overflow-y: visible;
    scrollbar-width: thin;
  }
  main .tab-section .nav::-webkit-scrollbar {
    height: 4px;
  }
  main .tab-section .nav::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  main .tab-section .nav::-webkit-scrollbar-thumb {
    background: #888;
  }
  main .tab-section .nav::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
main .tab-section .nav .nav-item .nav-link {
  background: none;
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #0D3453;
  padding-bottom: 15px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 820px) {
  main .tab-section .nav .nav-item .nav-link {
    font-size: 18px;
  }
}
main .tab-section .nav .nav-item .nav-link.active {
  font-weight: 600;
}
main .tab-section .nav .nav-item .nav-link.active::before {
  width: 100%;
}
main .tab-section .nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  margin: auto;
  width: 0;
  height: 3px;
  background: #5BAE47;
  transition: all 0.3s ease-in-out;
}
main .client-logo {
  width: 180px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 15px;
}
@media (max-width: 480px) {
  main .client-logo {
    width: 150px;
  }
}
main .client-name {
  width: 180px;
  text-align: center;
  margin-top: 10px;
  color: #0D3453;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
@media (max-width: 480px) {
  main .client-name {
    width: 150px;
  }
}
main .why_choose_block {
  background: #0D3453;
  padding: 60px 0;
  border-bottom: 1px solid #135470;
}
@media (max-width: 767px) {
  main .why_choose_block {
    padding: 50px 0;
  }
}
main .why_choose_block h2 {
  color: #ffffff;
  margin-bottom: 40px;
}
main .why_choose_block .why_choose_wrapper {
  display: flex;
}
main .why_choose_block .why_choose_wrapper > div {
  width: 20%;
}
main .why_choose_block .why_choose_wrapper > div:not(:last-child) {
  margin-right: 20px;
}
main .why_choose_block .why_choose_wrapper > div a {
  display: inline-block;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
main .why_choose_block .why_choose_wrapper > div a.play-button {
  margin: 0 0 20px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
main .why_choose_block .why_choose_wrapper > div a.play-button::before {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all 0.3s ease-in-out;
  top: auto;
  left: auto;
  bottom: 10px;
  right: 10px;
  margin: auto;
  background: #5BAE47;
  border: 3px solid rgba(255, 255, 255, 0.6);
  box-sizing: unset;
  background-clip: content-box;
  z-index: 1;
}
main .why_choose_block .why_choose_wrapper > div a.play-button:hover::before {
  background: #3f8d2c;
}
main .why_choose_block .why_choose_wrapper > div a.play-button::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: auto;
  left: auto;
  bottom: 23px;
  right: 22px;
  margin: 0;
  border-left: 12px solid #ffffff;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  z-index: 2;
}
main .why_choose_block .why_choose_wrapper > div a img {
  transition: all 0.3s ease-in-out;
}
main .why_choose_block .why_choose_wrapper > div a:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
main .why_choose_block .why_choose_wrapper > div h3 {
  font-size: 30px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 8px;
}
main .why_choose_block .why_choose_wrapper > div p {
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 0;
}
@media (max-width: 800px) {
  main .why_choose_block .why_choose_wrapper {
    overflow-x: auto;
    padding-bottom: 25px;
    scrollbar-width: thin;
  }
  main .why_choose_block .why_choose_wrapper::-webkit-scrollbar {
    height: 4px;
  }
  main .why_choose_block .why_choose_wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  main .why_choose_block .why_choose_wrapper::-webkit-scrollbar-thumb {
    background: #888;
  }
  main .why_choose_block .why_choose_wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  main .why_choose_block .why_choose_wrapper > div {
    width: 210px;
    max-width: 21px;
    min-width: 210px;
  }
}
main .univarietysJourney {
  padding: 100px 0;
  background: linear-gradient(0deg, #fff 50%, #d1f1ff 100%);
}
@media (max-width: 767px) {
  main .univarietysJourney {
    padding: 50px 0;
  }
}
main .univarietysJourney .univarietysJourneyWrap {
  position: relative;
  margin-top: 60px;
}
main .univarietysJourney .univarietysJourneyWrap .univarietysJourneyCenterLine {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  height: 100%;
  width: 5px;
  background: #5BAE47;
  border-radius: 4px;
}
@media (max-width: 767px) {
  main .univarietysJourney .univarietysJourneyWrap .univarietysJourneyCenterLine {
    right: auto;
  }
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont {
  padding: 78px 0 0;
  width: calc(50% - 55px);
  z-index: 9;
  position: relative;
}
@media (max-width: 767px) {
  main .univarietysJourney .univarietysJourneyWrap .uj_boxcont {
    padding: 30px 0 0 30px;
    width: 100%;
  }
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year .uj_boxcont_block {
  border: 1px solid #85bae4;
  border-radius: 4px;
  box-shadow: 0 5px 0 #d5ecff;
  padding: 35px 30px 40px 40px;
  position: relative;
  background: #ffffff;
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year .uj_boxcont_block ul.bullettick {
  margin: 0 0 25px;
  margin-bottom: 25px;
  padding: 0;
  list-style: inside;
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year .uj_boxcont_block ul.bullettick li {
  font-size: 18px;
  line-height: 30px;
  padding: 0 0 0 30px;
  list-style: none;
  position: relative;
  font-size: 20px;
  font-weight: 500;
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year .uj_boxcont_block ul.bullettick li::before {
  position: absolute;
  content: "";
  width: 13px;
  height: 8px;
  border-left: 3px solid #5bad49;
  border-bottom: 3px solid #5bad49;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  left: 0;
  top: 10px;
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year .uj_boxcont_block::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  right: -8px;
  top: 30px;
  border-top: 1px solid #85bae4;
  border-right: 1px solid #85bae4;
  background: #fff;
  box-shadow: 0 -2px 0 #d5ecff;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
@media (max-width: 767px) {
  main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year .uj_boxcont_block::before {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    right: auto;
    left: -8px;
  }
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year .uj_boxcont_block::after {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  right: -66px;
  top: 27px;
  margin: auto;
  border: 3px solid #fff;
  border-radius: 50%;
  background: #5BAE47;
}
@media (max-width: 767px) {
  main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year .uj_boxcont_block::after {
    left: -38px;
    right: auto;
  }
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year:nth-child(2n) {
  transform: translateX(calc(100% + 110px));
  -webkit-transform: translateX(calc(100% + 110px));
  -moz-transform: translateX(calc(100% + 110px));
  -ms-transform: translateX(calc(100% + 110px));
  -o-transform: translateX(calc(100% + 110px));
}
@media (max-width: 767px) {
  main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year:nth-child(2n) {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
  }
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year:nth-child(2n) .uj_boxcont_block::before {
  left: -8px;
  right: auto;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year:nth-child(2n) .uj_boxcont_block::after {
  left: -65px;
  right: auto;
}
@media (max-width: 767px) {
  main .univarietysJourney .univarietysJourneyWrap .uj_boxcont .uj_year:nth-child(2n) .uj_boxcont_block::after {
    left: -38px;
  }
}
main .univarietysJourney .univarietysJourneyWrap .uj_boxcont h3 {
  color: #5BAE47;
  margin: 10px 0 60px;
  font-size: 60px;
}
main .univariety_story {
  background: #0D3453;
  padding: 100px 0;
}
@media (max-width: 767px) {
  main .univariety_story {
    padding: 50px 0;
  }
}
main .univariety_story .nelson-quote {
  border-bottom: 1px solid #051929;
  padding: 0 120px 40px;
  margin: 0 0 60px;
}
@media (max-width: 850px) {
  main .univariety_story .nelson-quote {
    padding: 0 20px 40px;
    margin: 0 0 30px;
  }
}
main .univariety_story .nelson-quote .quote {
  color: #ffffff;
  position: relative;
  text-align: left;
  font-size: 25px;
  margin-top: 35px;
}
main .univariety_story .nelson-quote .quote::before {
  content: ' "';
  font-size: 216px;
  line-height: 216px;
  color: #5BAE47;
  position: absolute;
  left: -31px;
  top: -56px;
  z-index: 0;
  font-weight: 800;
}
main .univariety_story .nelson-quote .quote span {
  position: relative;
  z-index: 1;
}
main .univariety_story .nelson-quote .quote span.font-18 {
  font-size: 18px;
}
main .univariety_story .white-block {
  background: #fff;
  width: 77%;
  margin: 0 0 0 23%;
  padding: 45px 80px 45px 110px;
  border-radius: 4px;
  font-size: 18px;
}
@media (max-width: 850px) {
  main .univariety_story .white-block {
    width: 100%;
    padding: 30px;
    margin: 0;
  }
}
main .univariety_story .ceo_bio h3 {
  margin: 25px 0 0;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600;
}
@media (max-width: 575px) {
  main .univariety_story .ceo_bio h3 {
    text-align: center;
  }
}
main .univariety_story .ceo_bio p.post {
  padding: 0 10px 0 0;
  border-bottom: 7px solid #5BAE47;
  display: inline-block;
  line-height: 28px;
  font-size: 18px;
  color: #ffffff;
  margin: 0;
}
main .univariety_story .ceo_bio .brife P {
  color: #fff;
  text-align: left;
  font-size: 18px;
  line-height: 28px;
}
main .univariety_story .ceo_bio .brife P:not(:last-child) {
  margin: 0 0 25px;
}
main .univariety_story .ceo_bio .brife h3 {
  position: relative;
  margin: 30px 0 0;
  padding: 30px 0 0;
  line-height: 33px;
  font-weight: 400;
  text-align: left;
}
main .univariety_story .ceo_bio .brife h3::before {
  content: "";
  position: absolute;
  width: 97px;
  height: 4px;
  background: 0 0/100% auto #5BAE47;
  left: 0;
  top: 0;
  z-index: 2;
}
main .ourMentors {
  padding: 70px 0;
}
@media (max-width: 767px) {
  main .ourMentors {
    padding: 50px 0;
  }
}
main .ourMentors .mentor_brife_wrap {
  position: relative;
}
main .ourMentors .mentor_brife_wrap::before {
  content: "";
  position: absolute;
  width: 42px;
  height: 33px;
  background: url(../images/ourmentor02_lt.svg) left top/100% auto no-repeat;
  left: 8px;
  top: -13px;
  z-index: 2;
}
main .ourMentors .mentor_brife_wrap .mentor_brife_img::before {
  content: "";
  position: absolute;
  width: 66px;
  height: 65px;
  background: url(../images/ourmentor02_rt.svg) left top/100% auto no-repeat;
  right: 14px;
  top: -22px;
  z-index: 2;
}
main .ourMentors .mentor_brife_wrap .mentor_brife_img::after {
  content: "";
  position: absolute;
  width: 53px;
  height: 36px;
  background: url(../images/ourmentor02_rb.svg) left top/100% auto no-repeat;
  right: 28px;
  bottom: -18px;
  z-index: 2;
}
main .ourMentors .mentor_brife h3 {
  margin: 0;
  padding: 30px 0 15px;
  font-weight: 600;
}
main .ourMentors .mentor_brife h4 {
  color: #373737;
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 3px solid #5BAE47;
  line-height: 25px;
  font-size: 18px;
}
main .ourMentors .mentor_brife h4 span {
  font-weight: 600;
}
main .ourMentors .mentor_brife h4 em {
  font-weight: 400;
}
main .ourMentors .mentor_brife p {
  font-size: 18px;
  text-align: left;
}
main .meetTheTeam {
  background: #0D3453;
  padding: 100px 0;
}
@media (max-width: 767px) {
  main .meetTheTeam {
    padding: 50px 0;
  }
}
main .meetTheTeam .person-quote {
  border-bottom: 1px solid #051929;
  padding: 0 120px 40px;
  margin: 0 0 60px;
}
@media (max-width: 850px) {
  main .meetTheTeam .person-quote {
    padding: 0 20px 40px;
    margin: 0 0 30px;
  }
}
main .meetTheTeam .person-quote .quote {
  color: #ffffff;
  position: relative;
  text-align: left;
  font-size: 25px;
  margin-top: 35px;
}
main .meetTheTeam .person-quote .quote::before {
  content: ' "';
  font-size: 216px;
  line-height: 216px;
  color: #5BAE47;
  position: absolute;
  left: -31px;
  top: -56px;
  z-index: 0;
  font-weight: 800;
}
main .meetTheTeam .person-quote .quote span {
  position: relative;
  z-index: 1;
}
main .meetTheTeam .person-quote .quote span.font-18 {
  font-size: 18px;
}
main .meetTheTeam #team_mate {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1%;
  justify-content: center;
}
main .meetTheTeam #team_mate > .item {
  margin: 0 1% 25px;
}
main .meetTheTeam .teammate .main_container {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  max-width: 277px;
  min-width: 277px;
}
main .meetTheTeam .teammate .main_container .mate_name_position {
  padding: 20px;
}
main .meetTheTeam .teammate .main_container .mate_name_position h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}
main .meetTheTeam .teammate .main_container .mate_name_position h4 {
  letter-spacing: 0;
  color: #676972;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}
main .meetTheTeam .teammate .main_container .mate_name_position .plus {
  height: 16px;
  width: 16px;
  position: relative;
  margin: 6px 0 0;
}
main .meetTheTeam .teammate .main_container .mate_name_position .plus::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
  width: 16px;
  background: #5BAE47;
}
main .meetTheTeam .teammate .main_container .mate_name_position .plus::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 16px;
  width: 2px;
  background: #5BAE47;
  transition: all 0.3s ease-in-out;
}
main .meetTheTeam .teammate .main_container .mate_brife {
  position: absolute;
  top: 100%;
  background: #fff;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-bottom: 2px solid #f1f5f8;
  padding: 20px;
  transition: all 0.3s ease-in-out;
}
main .meetTheTeam .teammate .main_container .mate_brife .scrollMe {
  padding-right: 10px;
  max-height: 225px;
  overflow-y: scroll;
  scrollbar-width: thin;
}
main .meetTheTeam .teammate .main_container .mate_brife p {
  text-align: left;
  font-size: 15px;
  margin-bottom: 15px;
}
main .meetTheTeam .teammate .main_container .mate_brife p:last-child {
  margin-bottom: 0;
}
main .meetTheTeam .teammate .main_container:hover .mate_brife {
  top: 0;
}
main .meetTheTeam .teammate .main_container:hover .mate_name_position .plus::after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
}
main .backedBy {
  padding: 70px 0;
}
@media (max-width: 767px) {
  main .backedBy {
    padding: 50px 0;
  }
}
main .backedBy h2 {
  margin-bottom: 45px;
}
@media (max-width: 767px) {
  main .backedBy h2 {
    margin-bottom: 20px;
  }
}
main .backedBy h2 span {
  display: block;
  font-size: 80%;
}
main .backedBy h3 {
  font-size: 25px;
  margin-bottom: 20px;
}
main .backedBy p {
  font-size: 18px;
  text-align: left;
  color: #000000;
}
main .backedBy .green-box {
  background: #5BAE47;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 50px;
}
main .backedBy .green-box p {
  font-size: 25px;
  font-weight: 300;
}
main .univarietyCulture {
  padding: 70px 0 0;
  background: #0D3453;
  position: relative;
}
@media (max-width: 767px) {
  main .univarietyCulture {
    padding: 50px 0 0;
  }
}
main .univarietyCulture .univarietyCultureWrap {
  padding: 0 0 170px;
  position: relative;
}
@media (max-width: 767px) {
  main .univarietyCulture .univarietyCultureWrap {
    padding: 0 0 40px;
  }
}
main .univarietyCulture .univarietyCultureWrap .white-box {
  background: #ffffff;
  padding: 35px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
main .univarietyCulture .univarietyCultureWrap .white-box h3 {
  font-size: 30px;
  font-weight: 600;
}
main .univarietyCulture .univarietyCultureWrap .white-box p {
  text-align: left;
  line-height: 24px;
  font-size: 18px;
  color: #000;
}
main .univarietyCulture .univarietyCultureWrap .white-box p:not(:last-child) {
  margin: 0 0 15px;
}
main .univarietyCulture .sectionImg {
  position: absolute;
  bottom: 0;
  right: -90px;
}
@media (max-width: 767px) {
  main .univarietyCulture .sectionImg {
    position: static;
  }
}
main .awardsRecognition {
  padding: 70px 0;
}
@media (max-width: 767px) {
  main .awardsRecognition {
    padding: 50px 20px;
  }
}
main .awardsRecognition .awardsNumber {
  padding-bottom: 80px;
  margin-bottom: 60px;
  background: url(../images/dotted_bg.png) bottom left repeat-x;
}
main .awardsRecognition .awardsNumber .border-block {
  border: 1px solid #c0d9ec;
  border-radius: 4px;
  padding: 40px;
  position: relative;
  height: 100%;
}
main .awardsRecognition .awardsNumber .border-block::before {
  content: attr(data-award);
  font-size: 150px;
  font-weight: 800;
  position: absolute;
  left: -20px;
  bottom: -35px;
  z-index: 1;
  color: #5cae48;
  line-height: 150px;
}
main .awardsRecognition .awardsNumber .border-block h3 {
  min-height: 75px;
  font-weight: 600;
  font-size: 30px;
}
main .awardsRecognition .awardsNumber .border-block h3 span {
  font-weight: 400;
  font-size: 16px;
}
main .awardsRecognition .awardsNumber .border-block p {
  padding: 0 0 0 50px;
  text-align: left;
  color: #000000;
}
main .awardsRecognition .pathSection p {
  font-size: 18px;
  text-align: left;
  color: #000000;
  margin: 0;
}
main .awardsRecognition .pathSection p:not(:last-child) {
  margin: 0 0 15px;
}
main .contactMails {
  padding: 70px 0;
  background: #0D3453;
}
@media (max-width: 767px) {
  main .contactMails {
    padding: 50px 20px;
  }
}
main .contactMails .callout {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
main .contactMails .callout .icon {
  left: 0;
  right: 0;
  margin: auto;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1019607843);
  top: -32px;
  background: #ffffff;
}
main .contactMails .callout p {
  font-size: 14px;
  font-weight: 500;
}
main .contactMails .callout a {
  color: #0D3453;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}
main .contactMails .callout a:hover {
  color: #5BAE47;
}
main .contactForm {
  padding: 70px 0;
  background: linear-gradient(0deg, #fff 50%, #d1f1ff 100%) no-repeat padding-box;
  background-size: auto;
  background-size: 100% 580px;
}
@media (max-width: 767px) {
  main .contactForm {
    padding: 50px 20px;
  }
}
main .contactForm h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}
main .contactForm .greenListBlock {
  overflow-x: auto;
  overflow-y: visible;
  scrollbar-width: thin;
  margin-bottom: 30px;
}
main .contactForm .greenListBlock::-webkit-scrollbar {
  height: 4px;
}
main .contactForm .greenListBlock::-webkit-scrollbar-track {
  background: #f1f1f1;
}
main .contactForm .greenListBlock::-webkit-scrollbar-thumb {
  background: #888;
}
main .contactForm .greenListBlock::-webkit-scrollbar-thumb:hover {
  background: #555;
}
main .contactForm .greenListBlock ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
@media (max-width: 767px) {
  main .contactForm .greenListBlock ul {
    display: flex;
    margin-bottom: 15px;
  }
}
main .contactForm .greenListBlock ul li {
  background: #5cae48;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: #fff;
  padding: 20px 20px 20px 50px;
  font-size: 18px;
  position: relative;
}
@media (max-width: 767px) {
  main .contactForm .greenListBlock ul li {
    padding: 15px 15px 15px 50px;
    min-width: 270px;
  }
}
main .contactForm .greenListBlock ul li:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  main .contactForm .greenListBlock ul li:not(:last-child) {
    margin: 0 10px 0 0;
  }
}
main .contactForm .greenListBlock ul li i {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 20px;
  top: 24px;
  background: #fff;
}
@media (max-width: 767px) {
  main .contactForm .greenListBlock ul li i {
    top: 18px;
  }
}
main .contactForm .greenListBlock ul li i::before {
  content: "";
  position: absolute;
  width: 11px;
  height: 6px;
  border-left: 3px solid #5BAE47;
  border-bottom: 3px solid #5BAE47;
  top: 6px;
  left: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
main .contactForm .FormTab_scroll {
  overflow-x: auto;
  overflow-y: visible;
  scrollbar-width: thin;
  margin-bottom: 30px;
}
main .contactForm .FormTab_scroll::-webkit-scrollbar {
  height: 4px;
}
main .contactForm .FormTab_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
main .contactForm .FormTab_scroll::-webkit-scrollbar-thumb {
  background: #888;
}
main .contactForm .FormTab_scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
main .contactForm .nav-tabs {
  margin: 0 0 20px;
  border-bottom: 0 solid transparent;
  flex-wrap: nowrap;
  width: -moz-max-content;
  width: max-content;
  min-width: 100%;
}
main .contactForm .nav-tabs .nav-item:not(:last-child) {
  margin: 0 10px 0 0;
}
main .contactForm .nav-tabs .nav-item .nav-link {
  font-size: 18px;
  color: #0d3453;
  padding: 10px 22px;
  position: relative;
  background-color: #fff;
  border-color: #b6d7f1;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  transition: all 0.3s ease-in-out;
}
main .contactForm .nav-tabs .nav-item .nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  transition: all 0.3s ease-in-out;
}
main .contactForm .nav-tabs .nav-item .nav-link.active, main .contactForm .nav-tabs .nav-item .nav-link:hover {
  color: #fff;
  background-color: #0d3453;
  border-color: #0d3453;
}
main .contactForm .nav-tabs .nav-item .nav-link.active::after, main .contactForm .nav-tabs .nav-item .nav-link:hover::after {
  bottom: -7px;
  opacity: 1;
  border-top: 8px solid #0d3453;
}
main .contactForm .tab-content form {
  width: 90%;
  margin: 0 auto;
  max-width: 650px;
}
main .contactForm .tab-content form p {
  font-size: 13px;
  font-weight: 500;
}
main .contactForm .tab-content form .form-label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  width: 180px;
  font-weight: 500;
}
main .contactForm .tab-content form .form-control {
  height: 50px;
  line-height: 50px;
  padding: 0 0 0 10px;
  background: #fff;
  border: 1px solid #bacfde;
  border-radius: 4px;
}
main .contactForm .tab-content form .form-select {
  height: 50px;
  line-height: 50px;
  padding: 0 0 0 10px;
  border: 1px solid #bacfde;
  border-radius: 4px;
}
main .contactForm .tab-content form textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
  height: 80px;
}
main .contactForm .tab-content form .btn-submit {
  position: relative;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
  width: 164px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
main .contactForm .tab-content form .btn-submit.grdGreenBlue::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110%;
  height: 400%;
  background: transparent linear-gradient(271deg, #26A7DF 0%, #5BAE47 100%);
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.5s ease-in-out;
}
main .contactForm .tab-content form .btn-submit.grdOrangeGold {
  width: 196px;
}
main .contactForm .tab-content form .btn-submit.grdOrangeGold::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110%;
  height: 400%;
  background: transparent linear-gradient(271deg, #F05A2A 0%, #F8B018 100%);
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.5s ease-in-out;
}
main .contactForm .tab-content form .btn-submit.grdBlueLavender {
  width: 196px;
}
main .contactForm .tab-content form .btn-submit.grdBlueLavender::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 600%;
  background: transparent linear-gradient(271deg, #51499C 0%, #26A7DF 100%);
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.5s ease-in-out;
}
main .contactForm .tab-content form .btn-submit:hover::before {
  transform: translate3d(-50%, -50%, 0) rotate(-180deg);
}
main .contactForm .tab-content form .btn-submit span {
  position: relative;
  padding-top: 2px;
}
main .faqSectionWrap .faqSection {
  padding: 70px 0;
}
main .faqSectionWrap .faqSection h2 {
  margin-bottom: 20px;
}
main .faqSectionWrap .faqSection .accordion-item {
  border: 0;
  background: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
main .faqSectionWrap .faqSection .accordion-item:not(:last-child) {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}
main .faqSectionWrap .faqSection .accordion-item .accordion-button {
  border: 0;
  background: none;
  box-shadow: none;
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
main .faqSectionWrap .faqSection .accordion-item .accordion-button::after {
  background: url(../images/arrow.png) no-repeat;
  transform: translateY(5px);
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
}
main .faqSectionWrap .faqSection .accordion-item .accordion-button[aria-expanded=true]::after {
  transform: translateY(-2px) rotate(180deg);
  -webkit-transform: translateY(-2px) rotate(180deg);
  -moz-transform: translateY(-2px) rotate(180deg);
  -ms-transform: translateY(-2px) rotate(180deg);
  -o-transform: translateY(-2px) rotate(180deg);
}
main .faqSectionWrap .faqSection .accordion-item p {
  color: #000;
  font-size: 18px;
}
main .faqSectionWrap .faqSection .accordion-body {
  color: #000;
  font-size: 18px;
}
main .faqSectionWrap .faqSection .accordion-body p {
  color: #000;
  font-size: 18px;
}
main .faqSectionWrap .faqSection .accordion-body a {
  color: #5BAE47;
  transition: all 0.3s ease-in-out;
}
main .faqSectionWrap .faqSection .accordion-body a:hover {
  color: #26a7df;
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-item {
  border: 0;
  background: none;
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-item:not(:last-child) {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-item .accordion-button {
  border: 0;
  background: none;
  box-shadow: none;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-item .accordion-button::after {
  filter: invert(1);
  webkit-filter: invert(1);
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-item p {
  color: #fff;
  font-size: 18px;
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-body {
  color: #fff;
  font-size: 18px;
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-body p {
  color: #fff;
  font-size: 18px;
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-body a {
  color: #5BAE47;
  transition: all 0.3s ease-in-out;
}
main .faqSectionWrap .faqSection.bg_darkblue .accordion-body a:hover {
  color: #26a7df;
}
main .privacyPolicyHeader p.font-25 {
  font-size: 25px;
  font-weight: 500;
}
main .privacyPolicyHeader p.font-18 {
  font-size: 18px;
  margin: 0;
}
main .privacyPolicy {
  padding: 70px 0;
  background: linear-gradient(180deg, rgb(209, 241, 255) 0%, rgb(255, 255, 255) 13%);
}
@media (max-width: 767px) {
  main .privacyPolicy {
    padding: 50px 0px;
  }
}
main .privacyPolicy h2 {
  font-size: 25px;
  text-align: left;
  margin-top: 20px;
}
main .privacyPolicy p {
  text-align: left;
  font-size: 18px;
  margin: 0 0 15px;
  color: #000000;
}
main .privacyPolicy th {
  font-weight: 500;
}
main .refundPolicy {
  padding: 70px 0;
}
@media (max-width: 767px) {
  main .refundPolicy {
    padding: 50px 0px;
  }
}
main .checked-pointes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
main .checked-pointes .checked {
  width: 13px;
  height: 13px;
  border: 1px solid #8bd5b5;
  border-radius: 50%;
  margin-right: 4px;
  position: relative;
}
main .checked-pointes .checked::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 4px;
  border-left: 2px solid #70df91;
  border-bottom: 2px solid #70df91;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  left: 2px;
  top: 3px;
}
main .checked-pointes span {
  font-size: 14px;
  color: #999999;
}
main .property-block {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  box-shadow: 0 0px 7px 1px rgba(109, 109, 109, 0.4588235294);
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #7ed9f2;
}
main .property-block .property-details {
  width: calc(100% - 210px);
  padding: 20px;
}
@media (max-width: 767px) {
  main .property-block .property-details {
    width: 100%;
  }
}
main .property-block .property-details .wishList-share {
  font-size: 20px;
}
main .property-block .property-details .wishList-share [class*=btn] {
  color: #939393;
}
main .property-block .property-details .wishList-share [class*=btn]:hover, main .property-block .property-details .wishList-share [class*=btn].selected {
  color: #5BAE47;
}
main .property-block .property-details .gray-box {
  background: #f2f2f2;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}
main .property-block .property-details .gray-box .gray-box-list {
  width: calc(100% - 35px);
  padding: 5px 0;
}
@media (max-width: 767px) {
  main .property-block .property-details .gray-box .gray-box-list {
    width: 100%;
  }
}
main .property-block .property-details .gray-box .gray-box-list .gray-box-row {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}
main .property-block .property-details .gray-box .gray-box-list .gray-box-row .detail-block {
  width: 50%;
  padding: 8px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
main .property-block .property-details .gray-box .gray-box-list .gray-box-row .detail-block .icon {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-right: 9px;
  font-size: 22px;
  color: #5e5e5e;
}
main .property-block .property-details .gray-box .gray-box-list .gray-box-row .detail-block .detail-block-title-brife {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 39px);
}
main .property-block .property-details .gray-box .gray-box-list .gray-box-row .detail-block .detail-block-title-brife .detail-block-title {
  font-size: 12px;
  color: #8a8a8a;
  line-height: 16px;
  margin-bottom: 2px;
  text-transform: uppercase;
}
main .property-block .property-details .gray-box .gray-box-list .gray-box-row .detail-block .detail-block-title-brife .detail-block-brife {
  font-weight: 500;
  line-height: 16px;
  font-size: 15px;
}
main .property-block .property-details .gray-box-toggle {
  width: 35px;
  background: #dfdfdf;
}
@media (max-width: 767px) {
  main .property-block .property-details .gray-box-toggle {
    width: 100%;
    height: 35px;
  }
}
main .property-block .property-details .gray-box-toggle .btn-toggle {
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 20px;
  transition: transform 0.2s ease-in-out;
  background-position: center;
}
main .property-block .property-details .gray-box-toggle .btn-toggle[aria-expanded=true] {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
main .property-block .property-price-contact {
  width: 210px;
  background: #d1f5ff;
  padding: 20px;
}
@media (max-width: 767px) {
  main .property-block .property-price-contact {
    width: 100%;
  }
}
main .property-block .property-price-contact .price {
  font-size: 21px;
  font-weight: 500;
  line-height: 22px;
}
main .property-block .property-price-contact .price_per_sq {
  font-size: 14px;
}
main .property-block .property-price-contact .btn-contact-owner {
  background: #d00000;
  border-radius: 25px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
main .property-block .property-price-contact .btn-contact-owner:hover {
  color: #fff;
  background-color: #9e0101;
}
main .property-block .property-price-contact .btn-get-phone {
  background: transparent;
  border: 1px solid #d00000;
  border-radius: 25px;
  color: #d00000;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
main .property-block .property-price-contact .btn-get-phone:hover {
  color: #fff;
  background-color: #d00000;
}

footer .footer-row {
  padding: 0 0 40px;
  margin: 0 0 40px;
  border-bottom: 1px solid #071f31;
}
footer .footer-row:last-child {
  padding: 0;
  margin: 0;
  border-bottom: 0 solid #071f31;
}
footer .footer-row p {
  margin: 0 0 25px;
}
footer .footer-row ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
footer .footer-row ul li {
  font-weight: 400;
}
footer .footer-row ul li:not(:last-child) {
  margin: 0 0 10px;
}
footer .footerlinkcont a {
  text-decoration: underline;
  font-weight: 600;
}
footer .footerlinkcont a:hover {
  text-decoration: none;
}
footer a {
  color: #fff;
  text-decoration: none;
}
footer a:hover {
  color: #fff;
  text-decoration: underline;
}
footer .social-links a:not(:last-child) {
  margin-right: 5px;
}

#video_pop button.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff !important;
  opacity: 1;
  z-index: 999;
  background-size: 40%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#video_pop button.btn-close:hover {
  background-color: #5cae48 !important;
}
#video_pop button.btn-close span {
  display: none;
}

.loginModal .modal-content {
  border-radius: 30px;
}
.loginModal .modal-content h4 {
  text-align: center;
  margin-bottom: 30px;
}
.loginModal .modal-content .btn-close {
  top: 30px;
  right: 30px;
}
.loginModal .modal-content .form-control {
  height: 50px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  padding: 5px 25px;
}
.loginModal .modal-content .form-control-password {
  position: relative;
}
.loginModal .modal-content .form-control-password .viewpassword {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
  height: 13px;
  display: flex;
  align-items: center;
}
.loginModal .modal-content .btn-login {
  background: #5BAE47;
  padding: 10px 50px;
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease-in-out;
  color: #fff;
}
.loginModal .modal-content .btn-login:hover {
  background: #25461d;
  color: #fff;
}
.loginModal .modal-content .or {
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}
.loginModal .modal-content .or::after {
  background: #E6E6E6;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0px;
  top: 0;
  margin: auto;
  content: "";
  left: 0;
  right: 0;
  max-width: 463px;
}
.loginModal .modal-content .or b {
  font-weight: 400;
  font-family: "Jost", sans-serif;
  background: #e6e6e6;
  position: relative;
  z-index: 2;
  padding: 5px 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.loginModal .modal-content .sociallnks {
  text-align: center;
  padding-top: 0px;
}
.loginModal .modal-content .sociallnks p {
  font-size: 14px;
  line-height: 17px;
  color: #000;
  font-family: "Jost", sans-serif;
  margin: 0;
  font-weight: 400;
}
.loginModal .modal-content .sociallnks p a {
  color: #A2E609;
  font-weight: 500;
}
.loginModal .modal-content .sociallnks p a:hover {
  text-decoration: none;
}
.loginModal .modal-content .sociallnks ul {
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}
.loginModal .modal-content .sociallnks ul li {
  width: 100px;
  margin: 20px 10px;
}
.loginModal .modal-content .sociallnks ul li a {
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1019607843);
  width: 100%;
  display: flex;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
  height: 36px;
  justify-content: center;
  align-items: center;
}
.loginModal .modal-content .upload_section {
  background: #FFFFFF;
  border: 1px dashed rgba(11, 52, 84, 0.5019607843);
  border-radius: 6px;
  padding: 20px;
  position: relative;
}
.loginModal .modal-content .upload_section input {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  opacity: 0;
  z-index: 1;
  background: red;
}
.loginModal .modal-content .upload_section .cloud-wrap {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.loginModal .modal-content .upload_section .cloud-wrap .cloud-img {
  margin-right: 15px;
}
.loginModal .modal-content .upload_section .btn-upload {
  width: 170px;
}
.loginModal .modal-content .upload_section .cloud-wrap .cloud-text {
  flex: 1;
  line-height: 18px;
}
.loginModal .modal-content .form-check-input {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.loginModal .modal-content .upload_section u {
  color: #27a7df;
}

.item-block {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 14px;
  border-radius: 5px;
  width: 135px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  height: 90px;
  line-height: 16px;
  align-items: center;
}

.item-icon-wrap {
  margin-bottom: 5px;
}

.item-block .item-icon {
  width: 40px !important;
}

.regularCarousel.owl-theme .owl-nav {
  margin: 0;
}
@media (max-width: 767px) {
  .regularCarousel.owl-theme .owl-nav {
    margin-top: 20px;
    display: flex;
  }
}
.regularCarousel.owl-theme .owl-nav button {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  background-size: 20px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0 0 5px #ccc;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
@media (max-width: 767px) {
  .regularCarousel.owl-theme .owl-nav button {
    position: static;
  }
}
.regularCarousel.owl-theme .owl-nav button.owl-prev {
  left: -50px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
@media (max-width: 767px) {
  .regularCarousel.owl-theme .owl-nav button.owl-prev {
    margin: 0 10px 0 0;
  }
}
.regularCarousel.owl-theme .owl-nav button.owl-next {
  right: -50px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
@media (max-width: 767px) {
  .regularCarousel.owl-theme .owl-nav button.owl-next {
    margin: 0 0px 0 0;
  }
}
.regularCarousel.owl-theme .owl-nav button span {
  font-size: 0;
}
.regularCarousel.owl-theme .owl-nav button.disabled {
  opacity: 0;
}

.showcaseCarousel.owl-theme h3 {
  font-size: 18px;
  text-align: left;
  margin-bottom: 0px;
}
.showcaseCarousel.owl-theme h3 a {
  color: #000;
  text-decoration: none;
}
.showcaseCarousel.owl-theme p {
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
}
.showcaseCarousel.owl-theme p a {
  color: #000;
  text-decoration: none;
}
.showcaseCarousel.owl-theme .owl-nav {
  margin: 0;
}
@media (max-width: 767px) {
  .showcaseCarousel.owl-theme .owl-nav {
    margin-top: 20px;
    display: flex;
  }
}
.showcaseCarousel.owl-theme .owl-nav button {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  background-size: 20px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0 0 5px #ccc;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
@media (max-width: 767px) {
  .showcaseCarousel.owl-theme .owl-nav button {
    position: static;
  }
}
.showcaseCarousel.owl-theme .owl-nav button.owl-prev {
  left: -50px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
@media (max-width: 767px) {
  .showcaseCarousel.owl-theme .owl-nav button.owl-prev {
    margin: 0 10px 0 0;
  }
}
.showcaseCarousel.owl-theme .owl-nav button.owl-next {
  right: -50px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
@media (max-width: 767px) {
  .showcaseCarousel.owl-theme .owl-nav button.owl-next {
    margin: 0 0px 0 0;
  }
}
.showcaseCarousel.owl-theme .owl-nav button span {
  font-size: 0;
}
.showcaseCarousel.owl-theme .owl-nav button.disabled {
  opacity: 0;
}

.talent-detail h1 {
  font-size: 34px;
  margin: 0 0 5px;
  font-weight: 600;
  color: #0D3453;
}
.talent-detail h3 {
  font-size: 24px;
  margin: 0 0 5px;
  font-weight: 600;
  color: #333;
}
.talent-detail h3 a {
  color: #000;
  text-decoration: none;
}
.talent-detail p a {
  color: #000;
  text-decoration: none;
}

.skill-list {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.skill-list li {
  margin: 5px 5px 5px 0;
  background: #e6e6e6;
  border-radius: 25px;
  padding: 5px 15px;
}

.album_blk {
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  background: #f2f9ff;
  position: relative;
}
.album_blk .btn-delete {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  background: #000;
}
.album_blk .btn-delete::before {
  filter: invert(1);
}
.album_blk .btn-delete:hover {
  background-color: #fff;
}
.album_blk .btn-delete:hover::before {
  filter: invert(37%) sepia(81%) saturate(4260%) hue-rotate(335deg) brightness(98%) contrast(82%);
}

.album_badge {
  background: #d42941;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  border-radius: 6px 0 15px 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.album_imgblk {
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}
.album_imgblk img {
  width: 100%;
  height: auto;
}

.album_contblk {
  background: #fff;
  border-radius: 6px;
  width: calc(100% - 20px);
  margin: -40px auto 0;
  position: relative;
  padding: 20px 20px 15px;
}

.album_shareblk {
  width: 60px;
  padding: 0 0 0 20px;
}

.album_shareblk > span,
.album_shareblk > a.btn.btnicon {
  width: 40px;
  display: flex;
  height: 40px;
  line-height: 38px;
  border: 1px solid #dedede;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.album_shareblk > span:not(:last-child),
.album_shareblk > a.btn:not(:last-child) {
  margin: 0 0 5px;
}

.albumblk_footer {
  background: #f2f9ff;
  padding: 13px 30px;
  border-radius: 0 0 6px 6px;
}
.albumblk_footer img {
  margin: 0 6px 0 0;
}

.album_blk_parent {
  margin: 0 0 20px 0;
}

.album_imgvdo {
  right: 10px;
  top: 10px;
}

.image_tn {
  margin: 0 0 5px 0;
}
.image_tn > a {
  width: 63px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  color: #e1e1e1;
  font-size: 13px;
  text-decoration: none;
}
.image_tn > a img.gallicon {
  width: 14px;
  height: auto;
  margin: 0 3px 0 0;
}

img.custimg {
  width: auto;
  display: block;
  margin: 0 auto;
}

.icon_bluecir {
  background: #d1f1ff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count_block {
  background-color: #c4f2ff;
}

.project-title {
  font-size: 30px;
  font-weight: 400;
  text-align: left;
  line-height: 30px;
}
.project-title span {
  font-weight: 500;
}

.projects-subtitle {
  text-align: left;
  margin: 0;
  font-size: 20px;
}

.alumni_profile_brife_wrap {
  border-bottom: 1px solid #E7E7E7;
}

.alumni_profile_brife {
  padding: 40px 0;
}
.alumni_profile_brife .btn-back {
  color: #000;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.alumni_profile_brife .btn-back .icon-left-arrow {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  vertical-align: top;
}
.alumni_profile_brife .btn-back .icon-left-arrow:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  rotate: 45deg;
}
.alumni_profile_brife:hover {
  color: #0b3454;
  text-decoration: none;
}

.alumni_profile_name_college_other_detail_wrap {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}

.alumni_profile_image_name_other_detail {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  position: relative;
  width: 45%;
}

.alumni_profile_image_name_other_detail:after {
  content: "";
  width: 1px;
  height: 85%;
  background: #D4D4D4;
  position: absolute;
  right: 0;
  top: 0;
}

.alumni_profile_image {
  width: 110px;
  height: 110px;
  background: #F1F1F1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 20px;
}

.alumni_profile_image img {
  width: auto;
  height: 100%;
}

.alumni_profile_image.no_pic {
  align-items: end;
}

.alumni_profile_image.no_pic img {
  height: 80%;
}

.alumni_profile_name_other_detail {
  flex: 1;
}

.alumni_profile_name_other_detail h2 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  word-break: break-all;
  text-align: left;
}

.alumni_profile_name_other_detail p {
  font-size: 14px;
  text-align: left;
}

.alumni_profile_name_other_detail .alumni_profile_location {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: left;
}

.alumni_profile_class_college {
  position: relative;
  padding: 0 0 0 20px;
  margin-bottom: 13px;
}

.alumni_profile_class_college:before {
  content: "";
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  width: 3px;
  height: 100%;
  background: #D52941;
  border-radius: 5px;
}

.alumni_profile_class_college .alumni_profile_class {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 18px;
  margin-bottom: 5px;
}

.alumni_profile_class_college .alumni_profile_college {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 18px;
}

.alumni_profile_college_phone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.alumni_profile_college_phone .verifyLink {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.verifyLink {
  color: #E34252;
}

.verifiedLink {
  color: #5cae48;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.verifiedLink:hover {
  color: #2eac10;
}

.icon-varified {
  width: 16px;
  height: 19px;
  background: url(../images/varified.svg) no-repeat 0 0;
}

.student_profile {
  position: relative;
}
.student_profile .pencil-edit {
  opacity: 0.5;
  position: absolute;
  top: 12px;
  right: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.student_profile .pencil-edit:hover {
  opacity: 1;
}
.student_profile .alumni_profile_image_name_other_detail {
  width: 100%;
}
.student_profile .alumni_profile_image_name_other_detail:after {
  opacity: 0;
}
.student_profile .alumni_profile_class_college::before {
  background: #F8B018;
  left: 0;
}
.student_profile .school_other_details {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.student_profile .school_other_details li {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 10px;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}
.student_profile .school_other_details li:not(:last-child) {
  margin-right: 70px;
}
.student_profile .school_other_details li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  bottom: 0;
  width: 3px;
  height: 17px;
  background: #F8B018;
  border-radius: 5px;
}
.student_profile .school_other_details li b {
  font-weight: 500;
}

.student_profile_details {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  align-items: start;
}
.student_profile_details .alumni_profile_class_college {
  margin-right: 70px;
  padding-left: 17px;
  margin-bottom: 25px;
}
.student_profile_details .verifiedLink {
  margin-left: 20px;
}

.alumni_profile_detail {
  padding: 30px 0;
}

.alumni_profile_brife_tab .nav-item:not(:last-child) {
  margin-right: 10px;
}

.alumni_profile_brife_tab .nav-pills .nav-link {
  min-width: 100px;
  height: 40px;
  white-space: nowrap;
  border: 2px solid #7D7D7D;
  border-radius: 25px;
  background: #fff;
  color: #000;
  padding: 0 20px;
}

.alumni_profile_brife_tab .nav-pills .nav-link.active,
.alumni_profile_brife_tab .nav-pills .nav-link:hover {
  background: #0B3454;
  border-color: #0B3454;
  color: #fff;
}

@media (max-width: 1130px) {
  .alumni_profile_brife_tab {
    overflow: auto;
    scrollbar-width: thin;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .alumni_profile_brife_tab::-webkit-scrollbar {
    height: 4px;
  }
  .alumni_profile_brife_tab::-moz-scrollbar {
    height: 4px;
  }
  .alumni_profile_brife_tab::-webkit-scrollbar-track {
    background: #ccc;
  }
  .alumni_profile_brife_tab::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
  }
  .alumni_profile_brife_tab .nav {
    justify-content: start;
    flex-wrap: nowrap;
  }
}
.underGraduate-detail .main_title,
.blockHeader {
  font-size: 22px;
  color: #000;
  width: auto;
  margin: -40px -40px 25px;
  border-radius: 6px 6px 0px 0px;
  padding: 20px 40px;
  line-height: 22px;
  border-bottom: 1px solid #F5F5F5;
}

.blockHeader .title span {
  font-weight: 600;
}

.graduateRow .college_detail_wrap .college_detail h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 2px;
  transition: all 0.3s ease-in-out;
}

.graduateRow .college_detail_wrap:hover h3 {
  text-decoration: underline;
  color: #1452CE;
}

.graduateRow .college_detail_wrap .college_detail h3 .icon-subtraction {
  background: url(../images/subtraction.svg) no-repeat 0 0/100%;
  width: 16px;
  height: 25px;
  margin-left: 3px;
  display: inline-block;
  vertical-align: top;
}

.graduateRow .college_detail_wrap .college_detail p {
  font-size: 16px;
  color: #414141;
  margin: 0 0 5px;
}

.graduateRow {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.graduateRow:not(:last-child) {
  border-bottom: 2px solid #E5E5E5;
  margin-bottom: 28px;
  padding-bottom: 28px;
}

.underGraduate-detail,
.postGraduate-detail {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
  padding: 40px;
}

.graduateRow .college_detail_wrap {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  width: calc(100% - 265px);
  padding-right: 40px;
  position: relative;
  align-items: center;
}

.graduateRow .college_detail_wrap > a {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.graduateRow .college_detail_wrap:after {
  content: "";
  position: absolute;
  top: 13px;
  right: 0;
  width: 2px;
  height: 63px;
  background: #D1D1D1;
}

.graduateRow .offer {
  width: 265px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.graduateRow .college_detail_wrap .college_detail {
  flex: 1;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}

.graduateRow .college_detail_wrap .college_detail .college_logo {
  margin-right: 20px;
  width: 150px;
  height: 95px;
  border: 1px solid #D5D5D5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.college_name_scholarship {
  flex: 1;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.college_name_scholarship .college_name {
  width: calc(100% - 100px);
}

.graduateRow .college_detail_wrap .college_detail .college_logo .logo_link {
  padding: 10px;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.graduateRow .college_detail_wrap .college_detail .college_logo .logo_link img {
  width: 100%;
  height: 100%;
}

.graduateRow .college_detail_wrap .scholarship {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 20px;
}

.graduateRow .college_detail_wrap .scholarship .scholarship_label {
  font-weight: 500;
  color: #8A8A8A;
}

.graduateRow .college_detail_wrap .scholarship .scholarship_amt {
  font-weight: 600;
}

.graduateRow .offer > a,
.graduateRow .offer a.joindeLink {
  padding: 0 20px;
  text-decoration: none;
  height: 37px;
  background: #5CAE48;
  color: #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.graduateRow .offer > a:hover,
.graduateRow .offer a.joindeLink:hover {
  background: #3E9329;
}

.graduateRow .offer > a i,
.graduateRow .offer a.joindeLink i {
  margin-right: 10px;
}

.graduateRow .offer > a i.icon-like {
  background: url(../images/thumb-like.svg) no-repeat 0 0/18px;
  width: 19px;
  height: 18px;
  display: inline-block;
  filter: invert(1) brightness(1);
  -webkit-filter: invert(1) brightness(1);
}

.graduateRow .offer > a:hover {
  background: #3B8D27;
}

.graduateRow .offer .got_offer {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.graduateRow .offer .got_offer i {
  margin-right: 10px;
  font-size: 20px;
  color: #5cae48;
}

.graduateRow .offer .got_offer i.icon-like {
  background: url(../images/thumb-like.svg) no-repeat 0 0/18px;
  width: 19px;
  height: 18px;
  display: inline-block;
  filter: invert(58%) sepia(31%) saturate(773%) hue-rotate(62deg) brightness(96%) contrast(90%);
  -webkit-filter: invert(58%) sepia(31%) saturate(773%) hue-rotate(62deg) brightness(96%) contrast(90%);
}

.graduateRow .offer .offerLink {
  color: #1452CE;
}

.graduateRow .offer .offerLink:hover {
  color: #0b3454;
}

.graduateRow .offer .offer_joined i {
  background: url(../images/hat_alumni.svg);
  width: 23px;
  height: 17px;
  margin-right: 10px;
}

.graduateRow .offer .offer_joined {
  display: flex;
  align-items: center;
}

.graduateRow.edit .college_detail_wrap {
  width: calc(100% - 350px);
}

.graduateRow .offer.offer_edit {
  flex-direction: row;
  align-items: center;
  width: 350px;
  justify-content: end;
}

.graduateRow .offer.offer_edit .edit_section,
.edit_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;
  align-items: center;
}

.edit_section_inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.graduateRow .offer .edit_link,
.edit_section .edit_link {
  width: 52px;
  height: 30px;
  background: #EAF4FC;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.graduateRow .offer .edit_link:hover,
.edit_section .edit_link:hover {
  background-color: #0B3454;
}

.graduateRow .offer .edit_link:before,
.edit_section .edit_link::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background: url(../images/draw.svg) no-repeat center/18px auto;
}

.graduateRow .offer .edit_link:hover:before,
.edit_section .edit_link:hover::before {
  filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(110%) contrast(100%);
}

.graduateRow .offer .del_link,
.edit_section .del_link,
.del_link {
  background: url(../images/delete.svg);
  width: 14px;
  height: 16px;
  margin-left: 10px;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.graduateRow .offer .del_link:hover,
.edit_section .del_link:hover,
.del_link:hover {
  background: url(../images/delete_dark.svg);
  opacity: 1;
}

.graduateRow .offer .offer_existing_links {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.form-group .del_link {
  position: absolute;
  top: 15px;
  right: -20px;
}

.graduation-title {
  font-size: 22px;
  font-weight: 400;
  margin: -15px 0 20px;
}

.graduation-title span {
  font-weight: 600;
}

.graduation_carousel_wrap {
  margin-top: 20px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
  padding: 40px;
}

.graduation_carousel_wrap .card_profiles:first-child {
  margin-bottom: 18px;
}

.graduation_carousel_wrap .seactionheader {
  margin: -10px 0 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.seactionheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.countnbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seactionheader h3 {
  font-size: 24px;
  margin: 0;
}

.countnbtn .count {
  margin-right: 20px;
  font-style: italic;
  font-size: 14px;
  color: #0B3454;
}

.custom-btns a {
  display: inline-block;
  color: #0B3454;
  text-decoration: none;
  width: 16px;
  height: 16px;
  background: url(../images/left-arrow.svg) no-repeat 0 0/16px;
}

.custom-btns a.btn-next {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}

.custom-btns a:hover {
  color: #d42941;
}

.graduation_carousel_wrap .seactionheader h3 {
  font-size: 22px;
  font-weight: 400;
}

.graduation_carousel_wrap .seactionheader h3 span {
  font-weight: 600;
}

.card_profiles {
  border: 1px solid #D2D2D2;
  padding: 20px;
  border-radius: 6px;
  flex-direction: column !important;
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
  background: #fff;
  max-height: 240px;
  min-height: 240px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  overflow-y: auto;
  width: calc(100% - 1px);
}

.card_profiles::-webkit-scrollbar {
  width: 4px;
}

.card_profiles::-moz-scrollbar {
  width: 4px;
}

.card_profiles::-webkit-scrollbar-track {
  background: #ccc;
}

.card_profiles::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 20px;
  border: 2px solid #666;
}

.card_profiles .alumni-Profile-button-rounded {
  position: absolute;
  right: 20px;
  padding: 0 20px;
}

.card_profiles .alumni-Profile-button-rounded:hover,
.card_profiles .alumni-Profile-button-rounded:focus {
  background: #40952C;
  color: #fff;
  background-color: #40952C;
  border-color: 0;
  box-shadow: none;
}

.card_profiles figure.student {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 200px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_profiles figure.student img {
  border-radius: 200px;
}

.student_details {
  width: calc(100% - 100px);
}

.card_profiles .student_details h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 65%;
  margin-top: 10px;
}

.card_profiles .student_details h2 a {
  color: #000;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.card_profiles .student_details h2 a:hover {
  text-decoration: underline;
  color: #1452CE;
}

.card_profiles .student_details h2 span {
  display: block;
  font-size: 16px;
  color: #6A6868;
  font-weight: 500;
}

.card_profiles .student_details .sub_details {
  display: flex;
  flex-grow: 1 !important;
}

.card_profiles .student_details .sub_details_workExp {
  align-items: center;
  position: relative;
  padding-bottom: 5px;
}

.card_profiles .student_details .sub_details_achivements figure {
  background: #0B3454 !important;
}

.card_profiles .student_details .sub_details_workExp figure {
  border: 0 !important;
  border-radius: 100px !important;
  min-width: 50px !important;
  height: 50px !important;
  width: 50px !important;
  background-color: #D52941;
}

.card_profiles .student_details .sub_details_workExp figure img {
  width: 45%;
  height: auto;
}

.card_profiles .student_details .sub_details_workExp figure.bg_white {
  background-color: #fff !important;
  border: 1px solid #D2D2D2 !important;
}

.card_profiles .student_details .sub_details_workExp figure.bg_white img {
  width: 80%;
}

.card_profiles .student_details .sub_details_workExp:after {
  position: absolute;
  width: 83%;
  height: 1px;
  content: "";
  background: #C1C1C1;
  bottom: 0;
  right: 0;
  /* padding-bottom: 20px; */
}

.card_profiles .student_details .sub_details_workExp1:after {
  display: none;
}

.card_profiles .student_details .sub_details h3 {
  margin-bottom: 0;
  min-width: 43px;
  justify-content: center;
}

.card_profiles .student_details .sub_details figure {
  border: 1px solid #D2D2D2;
  border-radius: 100px;
  min-width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 0;
  width: 80px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_profiles .student_details .sub_details p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 43px;
  overflow: hidden;
  line-height: 20px;
}

.card_profiles .student_details .sub_details a {
  color: #000;
  text-decoration: none;
}

.card_profiles .student_details .sub_details a:hover {
  text-decoration: underline;
  color: #1452CE;
}

.card_profiles .student_details .sub_details p.title {
  color: #5CAE48;
  font-size: 14px;
  margin-bottom: 5px;
}

.card_profiles .student_details .sub_details span {
  color: #333333;
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 400;
}

.card_profiles .student_details .sub_details span.course {
  color: #0B3454;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.card_profiles .student_details .sub_details span.course img {
  margin-right: 6px;
  width: 16px;
}

.card_profiles .action_btns {
  margin-top: 20px;
  border-top: 1px solid #D2D2D2;
  padding-top: 20px;
  display: flex;
  justify-content: center !important;
  width: 100%;
}

.card_profiles .action_btns .btn-outline-primary {
  margin-right: 10px;
  border: 2px solid #5BAE47;
  border-radius: 50px;
  padding: 7px 20px;
  color: #5BAE47;
}

.card_profiles .action_btns .btn-outline-primary:hover img {
  filter: brightness(0) invert(1);
}

.card_profiles .action_btns .btn-outline-primary:hover {
  border: 2px solid #418d2e;
  background-color: #418d2e;
  color: #fff;
}

.card_profiles .action_btns .btn-secondary {
  border-radius: 50px;
  padding: 7px 20px;
  color: #fff;
  background: #0B3454;
}

.card_profiles .action_btns .btn-secondary:hover {
  background: #26A7DF;
  border: 1px solid #26A7DF;
}

.card_profiles .btn-send {
  width: 123px;
  height: 40px;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  background: #5CAE48;
  border-radius: 25pc;
  color: #fff;
  text-decoration: none;
  align-items: center;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.card_profiles .btn-send {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 20px;
  right: 20px;
}

.card_profiles .btn-send .icon-send {
  background: url(../images/send_alumni_profile.svg) no-repeat 0 0/100%;
  width: 19px;
  height: 19px;
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease-in-out;
  left: 0;
}

.card_profiles .btn-send:hover .icon-send {
  left: 3px;
}

.card_profiles .btn-send:hover {
  background: #3B8D27;
}

.workExperience-detail {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
  padding: 40px;
}

.workExperience-detail .workExperience-title {
  font-size: 22px;
  font-weight: 400;
  margin: -15px 0 30px;
}

.workExperience-detail .workExperience-title span {
  font-weight: 600;
}

.work-block {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.work-block .work-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.work-block .icon {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  margin-right: 20px;
  background: #d52941;
  border-radius: 50%;
  color: #fff;
  position: relative;
}

.work-block .icon::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../images/brife-case.svg) no-repeat 0 0/26px;
  width: 26px;
  height: 24px;
  margin: auto;
  filter: invert(1);
  -webkit-filter: invert(1);
}

.work-block .work-detail p {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 5px;
  font-weight: 500;
}

.work-block .work-detail span {
  font-size: 16px;
  color: #6D6B6B;
  line-height: 16px;
  font-weight: 500;
}

.achievements-block {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
  padding: 30px;
  display: flex;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  position: relative;
}

.achievements-block:not(:last-child) {
  border-bottom: 1px solid #F5F5F5;
}

.achievements-icon-block {
  padding: 0 55px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 185px;
  max-width: 185px;
  align-items: center;
  text-align: center;
  border-right: 1px solid #CECECE;
  margin-right: 30px;
}

.achievements-icon-block .icon {
  width: 60px;
  height: 60px;
  background: #0b3454;
  border-radius: 50%;
  margin-bottom: 8px;
  position: relative;
}

.achievements-icon-block .icon.icon-medal:before {
  content: "";
  background: url(../images/award_ico.svg);
  width: 26px;
  height: 36px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  margin: auto;
}

.achievements-icon-block .icon.icon-grade:before {
  content: "";
  background: url(../images/exam_ico.svg);
  width: 25px;
  height: 31px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  margin: auto;
}

.achievements-icon-block .achievements-title {
  font-size: 18px;
  font-weight: 600;
}

.achievements-icon-block .achievements-year {
  font-size: 18px;
  line-height: 22px;
  color: #414141;
}

.achievements-block-detail h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 0px;
}

.achievements-block-detail h3 span {
  font-weight: 600;
}

.achievements-block-detail p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.achievements-block-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.hide {
  display: none;
}

.achievements-block-detail p.show .hide {
  display: inline;
}

.achievements-block-detail p.show .btn-readmore {
  font-size: 0;
}

.achievements-block-detail p.show .btn-readmore:before {
  content: "Read Less";
  font-size: 16px;
}

.achievements-block-detail p .btn-readmore {
  color: #1452CE;
  transition: all 0.3s ease-in-out;
}

.achievements-block-detail p .btn-readmore:hover {
  color: #1046B3;
}

.achievements-block-detail .url-link {
  color: #1452CE;
  transition: all 0.3s ease-in-out;
}

.achievements-block-detail .url-link:hover {
  color: #1046B3;
}

.achievements-list {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
}

.achievements-block-title {
  padding: 20px 40px;
  border-bottom: 1px solid #F5F5F5;
  border-radius: 6px 6px 0 0;
}

.achievements-block-title .title {
  font-size: 22px;
}

.achievements-block-title .title span {
  font-weight: 600;
}

.btn-add-section {
  background: #FFFFFF;
  border: 1px solid #0B3454;
  border-radius: 19px;
  height: 37px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: #0B3454;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.btn-add-section i {
  position: relative;
  width: 11px;
  height: 110px;
  margin-right: 10px;
}

.btn-add-section i:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #0B3454;
  width: 2px;
  height: 10px;
}

.btn-add-section i:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #0B3454;
  width: 10px;
  height: 2px;
}

.btn-add-section:hover {
  background: #0B3454;
  color: #fff;
}

.btn-add-section:hover i:before,
.btn-add-section:hover i:after {
  background: #fff;
}

.contributions-block {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
  padding: 40px;
}

.contributions-block:not(:last-child) {
  margin-bottom: 20px;
}

.contributions-block .act_lst:not(:last-child) {
  margin-bottom: 20px;
}

.contributions-block .seactionheader {
  margin: -10px 0 20px;
}

.contributions-block h3 {
  font-size: 22px;
  font-weight: 600;
}

.contributions-block .circle-effect::before {
  background: #6C8598;
}

.contributions-block .list_badge {
  color: #D52941;
  background: no-repeat;
  position: static;
  text-transform: uppercase;
}

.contributions-block .act_lst {
  padding: 25px 25px 25px 25px;
}

.contributions-block .video_block_name label {
  font-size: 16px;
  flex: 1;
}

.skills-block {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
  padding: 40px;
  position: relative;
}

.skills-block:first-child {
  padding-bottom: 20px;
}

.skills-block.EntranceExam-skills-block {
  padding-bottom: 33px;
}

.skills-block:not(:last-child) {
  margin-bottom: 20px;
}

.skills-block h2 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 15px;
  margin: -10px 0 25px;
}

.skills-block h2 span {
  font-weight: 600;
}

.skills-block-detail {
  display: flex;
  flex-wrap: wrap;
}

.meter {
  margin: 0 12px 20px 0;
  border: 1px solid #C7C7C7;
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
}

.graduationslider .meter span {
  line-height: 18px;
}

.skills-block-detail .meter img {
  width: 50px;
}

.skills-block-title {
  margin-top: -30px;
  margin-bottom: 20px;
}

.preferences-block {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
  padding: 40px;
}

.preferences-block:not(:last-child) {
  margin-bottom: 20px;
}

.preferences-block.EntranceExam-skills-block {
  padding-bottom: 33px;
}

.preferences-block h2 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 15px;
  margin: -10px 0 25px;
}

.preferences-block h2 span {
  font-weight: 600;
}

.preferences-block-detail {
  display: flex;
  flex-wrap: wrap;
}

.preferences-block-detail .meter {
  margin: 0 12px 20px 0;
  border: 1px solid #C7C7C7;
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
}

.preferences-block-detail .meter img {
  width: 50px;
}

.preferences-block-title {
  margin-top: -30px;
  margin-bottom: 20px;
}

.interested-block .icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #0b3454;
  position: relative;
  margin-right: 20px;
}

.interested-block .icon.icon-bulb::before {
  content: "";
  background: url(../images/bulb.svg) no-repeat 0 0/34px auto;
  width: 34px;
  height: 36px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  margin: auto;
}

.interested-block .icon.icon-note::before {
  content: "";
  background: url(../images/publication_ico.svg);
  width: 29px;
  height: 31px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.interested-block {
  display: flex;
  align-items: center;
}

.interested-block .interested-topics {
  font-size: 20px;
  flex: 1;
  line-height: 22px;
}

.interested-block .interested-topics span {
  font-weight: 500;
}

.graduationslider .meter {
  padding: 10px 30px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.meter {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 18px;
  opacity: 1;
  padding: 5px 15px;
  transition: all 0.3s ease-in-out;
  margin-right: 5px;
  letter-spacing: 0.32px;
}

.profile-item {
  text-align: center;
  width: 196px;
  height: 210px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.owl-item:not(:last-child) .profile-item {
  border-right: 1px solid #D2D2D2;
}

.profile-item-img {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 11px;
}

.profile-item-name-class h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px;
}

.profile-item-name-class p {
  margin: 0 0 11px;
  font-size: 14px;
  font-style: italic;
  color: #535353;
}

.profile-item-name-class .subject {
  color: #0B3454;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
  padding-top: 10px;
}

.profile-item-name-class .subject:before {
  content: "";
  width: 30px;
  height: 2px;
  background: #5CAE48;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
}

.profile-item-name-class .btn-viewcompus {
  font-size: 13px;
  color: #1452CE;
  font-weight: 500;
  line-height: 18px;
  position: relative;
  z-index: 2;
  text-decoration: none;
}

.profile-item-name-class .btn-viewcompus:hover {
  color: #0b3454;
  text-decoration: underline;
}

.psychology-item {
  text-align: center;
  width: 245px;
  height: 196px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
}

.owl-item:not(:last-child) .psychology-item {
  border-right: 1px solid #D2D2D2;
}

.popularCollegesslider .owl-item.active + .owl-item.active + .owl-item.active + .owl-item.active .psychology-item {
  border-right: 0px solid #D2D2D2;
}

.psychology-item-img {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: 90px;
  align-items: center;
}

.psychology-item-name h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 5px;
  line-height: 17px;
}

.psychology-item-name p {
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px;
}

.alumni_directory_details .btn-working {
  font-size: 13px;
  color: #1452CE;
  font-weight: 500;
  line-height: 18px;
  position: relative;
  z-index: 2;
  text-decoration: none;
}

.alumni_directory_details .btn-working:hover {
  color: #0b3454;
  text-decoration: underline;
}

.tab-white-block {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  background: #fff;
  padding: o;
  position: relative;
}
.tab-white-block .tab-white-block-header {
  padding: 20px 30px;
  border-bottom: 1px solid rgb(238, 238, 238);
}
.tab-white-block .tab-white-block-body {
  padding: 30px;
}

.customRow:not(:last-child) {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #DBDBDB;
}

.customRow-header {
  display: flex;
  align-items: center;
}

.customRow-header .icon {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-color: #F8B018;
  margin-right: 10px;
}

.icon.icon-book {
  background-image: url(../images/open-book.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-global {
  background-image: url(../images/world.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-university-college {
  background-image: url(../images/college.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-book {
  background-image: url(../images/open-book.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-global {
  background-image: url(../images/world.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-father {
  background-image: url(../images/father.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-mother {
  background-image: url(../images/mother.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-guardian {
  background-image: url(../images/guardian.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-college {
  background-image: url(../images/edu-college.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-school {
  background-image: url(../images/school-bag.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-exam-note {
  background-image: url(../images/exam-note.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-aboutMe {
  background-image: url(../images/about-me.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-achievements {
  background-image: url(../images/trophy.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
}

.icon.icon-books {
  background-image: url(../images/book.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.icon.icon-activities {
  background-image: url(../images/target.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.customRow-detail {
  padding: 0 0 0 58px;
}

.tab-white-block .pencil-edit {
  background-color: #BEE0FB;
  width: 52px;
  height: 37px;
  border-radius: 19px;
  opacity: 0.5;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  background-image: url(../images/draw.svg);
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.tab-white-block .pencil-edit:hover {
  opacity: 1;
}

.curriculars-item {
  background: #FFFFFF;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.031372549);
  border-radius: 6px;
  position: relative;
}

.curriculars-item:not(:last-child) {
  margin-bottom: 10px;
}

.curriculars-header .customRow-header {
  padding: 20px 20px 20px 40px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.customRow-header-title {
  width: calc(100% - 110px);
}

.curriculars-body {
  padding: 0px 20px 20px 40px;
}

.curriculars-header .customRow-header[aria-expanded=true] {
  padding-bottom: 10px;
}

.curriculars-header .pencil-edit {
  background-color: #BEE0FB;
  width: 52px;
  height: 37px;
  border-radius: 19px;
  opacity: 0.5;
  position: absolute;
  top: 25px;
  right: 60px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  background-image: url(../images/draw.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.curriculars-header .pencil-edit:hover {
  opacity: 1;
}

tr:nth-child(even) {
  background: rgba(241, 241, 241, 0.4392156863);
}

.curriculars_table th div {
  padding: 8px 20px;
  background-color: #f1f1f1;
  font-size: 16px;
  font-weight: 400;
}

.curriculars_table thead th:first-child div {
  border-radius: 5px 0px 0px 5px;
}

.curriculars_table thead th:last-child div {
  border-radius: 0px 5px 5px 0px;
}

.curriculars_table tbody th {
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
}

.curriculars_table td {
  padding: 20px;
}

.curriculars-header .customRow-header i {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 30px;
  top: 36px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.curriculars-header .customRow-header[aria-expanded=true] i:before {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.curriculars-header .customRow-header i:before {
  content: "";
  width: 2px;
  height: 16px;
  background: #0D4466;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  transition: all 0.3s ease-in-out;
}

.curriculars-header .customRow-header i:after {
  content: "";
  width: 16px;
  height: 2px;
  background: #0D4466;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.book-wrap {
  display: flex;
  flex-wrap: wrap;
}

.book-wrap .book-detial {
  width: calc(100% - 80px);
  margin-left: 15px;
}
.book-wrap .book-detial p {
  font-size: 16px;
  text-align: left;
}

.book-wrap .book-img img {
  box-shadow: 0 0 3px 1px #ccc;
}

.lineHeight-26 {
  line-height: 26px;
}

@media (max-width: 575px) {
  .curriculars-header .customRow-header {
    padding: 24px 20px 24px 24px;
  }
  .curriculars-body {
    padding: 0px 20px 20px 24px;
  }
  .curriculars-body .customRow-detail {
    padding: 0;
  }
  .curriculars-header .pencil-edit {
    top: 25px;
  }
  .curriculars-header .customRow-header i {
    top: 35px;
  }
}
.xscroll {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  position: relative;
  z-index: 8;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.xscroll::-webkit-scrollbar {
  height: 5px;
}
.xscroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.xscroll::-webkit-scrollbar-thumb {
  background: #888;
}
.xscroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mainbanner {
  position: relative;
  background: url(../images/main-banner.png) no-repeat center/cover;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainbanner.realGullyBoyBanner {
  min-height: 990px;
  background: url(../images/realgullyboy-banner.png) no-repeat center/cover;
}
.mainbanner.realGullyBoyBanner .caption {
  font-weight: 800;
  font-size: 80px;
  line-height: 65px;
  letter-spacing: 0;
  position: relative;
}
.mainbanner.realGullyBoyBanner .caption .sub-caption {
  display: block;
  font-size: 30px;
  line-height: 80px;
  font-weight: 400;
}
.mainbanner.realGullyBoyBanner .caption .date {
  line-height: 18px;
  font-size: 18px;
  font-weight: 500;
  opacity: 0.8;
  vertical-align: top;
  margin-top: 0;
}
.mainbanner .caption {
  letter-spacing: 17.82px;
  color: #FFFFFF;
  font-size: 60px;
  line-height: 65px;
  text-align: center;
  font-weight: 500;
}
@media (max-width: 767px) {
  .mainbanner {
    background: url(../images/main-banner.png) no-repeat right 30% center/cover;
  }
  .mainbanner.realGullyBoyBanner {
    min-height: 500px;
  }
  .mainbanner.realGullyBoyBanner .caption {
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
  }
  .mainbanner.realGullyBoyBanner .caption .sub-caption {
    font-size: 20px;
    line-height: 45px;
  }
}

.page-content {
  margin: 40px auto 45px;
  max-width: 990px;
  width: 96%;
}
.page-content .categories-tab {
  overflow: auto;
}
.page-content .categories-tab .nav {
  padding: 0;
  border: 0;
  margin-bottom: 40px;
  justify-content: center;
}
.page-content .categories-tab .nav .nav-item:not(:last-child) {
  margin-right: 34px;
}
@media (max-width: 575px) {
  .page-content .categories-tab .nav .nav-item:not(:last-child) {
    margin-right: 10px;
  }
}
.page-content .categories-tab .nav .nav-link {
  background: #fff;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.0392156863);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  transition: all 0.3s ease-in-out;
  padding: 0 31px;
  border: 0;
  line-height: 45px;
  color: #0D3453;
}
.page-content .categories-tab .nav .nav-link:hover, .page-content .categories-tab .nav .nav-link.active {
  background: #0D3453;
  color: #fff;
}
@media (max-width: 575px) {
  .page-content .categories-tab .nav .nav-link {
    padding: 0 20px;
  }
}
@media (max-width: 905px) {
  .page-content .categories-tab .nav {
    width: -moz-max-content;
    width: max-content;
  }
}
.page-content .main-blog {
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.page-content .main-blog .blog-detail {
  padding: 0;
}
.page-content .main-blog .blog-detail .category {
  background: rgba(0, 0, 0, 0.5490196078);
  border: 1px solid #FFFFFF;
  border-radius: 15px;
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 30px;
  color: #fff;
  padding: 0 15px;
  height: 30px;
  line-height: 28px;
  font-size: 12px;
  font-weight: 500;
}
.page-content .main-blog .blog-detail .blog-title {
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-size: 30px;
  line-height: 35px;
  font-weight: 600;
  color: #fff;
  max-width: 510px;
}
.page-content .main-blog .blog-detail .blog-title span.date {
  font-size: 14px;
  font-weight: 600;
  display: block;
  opacity: 0.8;
  line-height: 30px;
}
@media (max-width: 575px) {
  .page-content .main-blog .blog-detail {
    padding: 25px 28px;
  }
  .page-content .main-blog .blog-detail .category {
    position: static;
    background: #FFFFFF;
    border: 1px solid #D52941;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 0 15px;
    height: 30px;
    display: inline-block;
    font-size: 12px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #D52941;
  }
  .page-content .main-blog .blog-detail .blog-title {
    position: static;
    color: #000;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }
}
.page-content .main-blog img {
  transition: all 0.3s ease-in-out;
}
.page-content .main-blog:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.page-content .blog-block {
  background: #FFFFFF;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.0784313725);
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.page-content .blog-block .blog-detail {
  padding: 25px 28px;
}
.page-content .blog-block .blog-detail .blog-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
.page-content .blog-block .blog-detail .blog-title .date {
  opacity: 0.7;
  font-size: 14px;
  display: block;
  font-weight: 600;
  line-height: 20px;
}
.page-content .blog-block .blog-detail .category {
  background: #FFFFFF;
  border: 1px solid #D52941;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 0 15px;
  height: 30px;
  display: inline-block;
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 500;
}
.page-content .blog-block .blog-detail .category.red {
  color: #D52941;
  border-color: #D52941;
}
.page-content .blog-block .blog-detail .category.blue {
  color: #26A9E1;
  border-color: #26A9E1;
}
.page-content .blog-block .blog-detail .category.yellow {
  color: #FBB217;
  border-color: #FBB217;
}
.page-content .blog-block:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.page-content .blog-img {
  vertical-align: top;
  overflow: hidden;
}
.page-content .blog-img img {
  transition: all 0.3s ease-in-out;
}

.blog-detail-page .page-title h1.title {
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  line-height: 37px;
}
.blog-detail-page .page-title p.sub-tite {
  margin: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.blog-detail-page .video-btn {
  position: relative;
}
.blog-detail-page .video-btn .icon-play {
  width: 75px;
  height: 53px;
  background: url(../images/youtube.svg);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.blog-detail-page .video-btn::before {
  content: "";
  width: 75px;
  height: 53px;
  background: url(../images/youtube.svg);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.leftPaneImg {
  background: url(../images/left-img.png) no-repeat right bottom/cover;
  width: 100%;
  height: 100%;
  display: inline-block;
  min-height: 700px;
}

.rightpane {
  padding-left: 60px;
}
@media (max-width: 991px) {
  .rightpane {
    padding: 0 50px;
  }
}
@media (max-width: 575px) {
  .rightpane {
    padding: 30px;
  }
  .rightpane .rightSideForm {
    max-width: 100%;
    padding: 70px 30px 43px;
  }
}
.rightpane h2 {
  font-size: 50px;
  line-height: 54px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
}
.rightpane h3 {
  color: #fff;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 20px;
  text-align: left;
}
.rightpane p {
  font-size: 18px;
  line-height: 25px;
  text-align: left;
}
.rightpane ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}
.rightpane ul li {
  position: relative;
  font-size: 18px;
  padding-left: 24px;
  margin-bottom: 6px;
}
.rightpane ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #000;
}
.rightpane .form-control, .rightpane .form-select {
  border: 1px solid #BACFDE;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
}

.leftPane h2 {
  position: relative;
  font-size: 50px;
  font-weight: 600;
  line-height: 54px;
  margin-bottom: 45px;
  text-align: left;
}
.leftPane h2::before {
  position: absolute;
  content: "";
  left: 0;
  top: 109%;
  width: 65px;
  height: 6px;
  background: #D52941;
  border-radius: 6px;
}
@media (max-width: 575px) {
  .leftPane h2 {
    font-size: 34px;
  }
}

.recent_stories {
  padding: 0;
  margin: 0 80px 0 0;
  list-style: none;
}
.recent_stories li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  max-width: 480px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.recent_stories li .recent_stories_thumb {
  max-width: 200px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}
.recent_stories li .recent_stories_thumb img {
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.recent_stories li:hover .recent_stories_thumb img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.recent_stories li .recent_stories_detail {
  padding: 12px 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.recent_stories li .recent_stories_detail .date {
  font-size: 14px;
  display: block;
  line-height: 32px;
  font-weight: 600;
  opacity: 0.7;
}
.recent_stories .recentLink {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media (max-width: 1199px) {
  .recent_stories {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
  }
  .recent_stories li {
    flex-direction: column;
    align-items: start;
  }
}
@media (max-width: 1199px) and (max-width: 575px) {
  .recent_stories li .recent_stories_detail {
    padding: 10px 0 0;
  }
}

.rightSideForm {
  padding: 70px 60px 43px;
  background: #FFFFFF url(../images/mask-group-18962.svg) no-repeat 0 0;
  border-radius: 4px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.0901960784);
  max-width: 463px;
}
.rightSideForm.rightGraph {
  background: #FFF url(../images/right-form-graph.svg) no-repeat top right;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.0901960784);
  border: 1px solid #DBDBDB;
  max-width: 529px;
}
.rightSideForm.rightGraph h3 {
  font-size: 40px;
  font-weight: bold;
  color: #000;
}

.bg-form {
  background: #B72134 url(../images/group-40752.svg) no-repeat left 343px bottom;
}

.form-control:focus-visible, .form-control:focus, .form-select:focus-visible, .form-select:focus {
  outline: 1px solid #25506F;
}

.fold-wrap ul.listed-points {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}
.fold-wrap ul.listed-points li {
  position: relative;
  font-size: 18px;
  padding-left: 24px;
  margin-bottom: 6px;
  max-width: 550px;
  width: 100%;
}
.fold-wrap ul.listed-points li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #000;
}
.fold-wrap .fold-section {
  background: #fff;
  padding: 0;
}
.fold-wrap .fold-section:nth-child(2n) {
  background-color: #F4F6F9;
}
.fold-wrap .fold-section:nth-child(2n) .added-img {
  background: #CCCDCE;
}
.fold-wrap .added-img {
  max-width: 500px;
  width: 100%;
  min-height: 350px;
  margin: auto;
  background: #F4F6F9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.fold-wrap .added-img .img-options {
  position: absolute;
  bottom: 20px;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}
.fold-wrap .added-img .img-options .img-upload {
  margin-right: 10px;
  background: #fff;
  padding: 0 13px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.fold-wrap .added-img .img-options .img-upload .btn-browse {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.fold-wrap .added-img .img-options .img-upload .btn-browse i {
  display: flex;
}
.fold-wrap .added-img .img-options .img-upload .upload-img {
  margin-bottom: 0;
}
.fold-wrap .fold-section-ui {
  max-width: 1140px;
  margin: auto;
  padding: 70px 0;
  width: 90%;
}
.fold-wrap .fold-section-ui h1 {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 26px;
}
.fold-wrap .fold-section-ui p {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 20px;
  text-align: left;
}
.fold-wrap .img-alignment .form-select {
  border: 0;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='23343a40' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='0' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e");
  background-size: 15px;
}
.fold-wrap .added-video {
  max-width: 500px;
  width: 100%;
  min-height: 350px;
  margin: auto;
  background: #F4F6F9;
  display: flex;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.fold-wrap .input-group.video-link {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 1;
  width: auto;
  margin: auto;
  background: #F4FBFE;
  border: 1px solid rgba(11, 52, 84, 0.3019607843);
  border-radius: 4px;
  height: 40px;
}
.fold-wrap .input-group.video-link .input-group-text {
  height: 100%;
  background: #F4FBFE;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 10px;
}
.fold-wrap .input-group.video-link .input-group-text i {
  margin-right: 10px;
}
.fold-wrap .input-group.video-link .form-control {
  height: 100%;
  border: none;
}
.fold-wrap .videoSection {
  position: relative;
}

.realGullyBoy h1 {
  font-size: 80px;
  font-weight: 800;
  line-height: 96px;
}
.realGullyBoy .sub-title {
  font-size: 30px;
  line-height: 43px;
  font-weight: 500;
  max-width: 387px;
  width: 100%;
  margin: 20px 0;
}
.realGullyBoy p {
  line-height: 29px;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: left;
}

.scrolldown {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  width: 117px;
  color: #ffffff;
}

.scrolldown b {
  width: 33px;
  height: 48px;
  font-size: 0;
  background: url(../images/mouse.svg) no-repeat 0 0/100%;
}

.float_animate {
  animation: float 4s ease-in-out infinite;
  -webkit-animation: float 4s ease-in-out infinite;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}

@keyframes float {
  0% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
  }
}
.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 575px) {
  .py-80 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.btn-green {
  background: #5BAE47;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 0 28px;
  line-height: 40px;
  border: 0;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}
.btn-green:hover {
  background: #0D3453;
  color: #ffffff;
}
.btn-cancel {
  color: #707070;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.btn-cancel:hover {
  color: #000;
}
.btn-submit {
  width: 150px;
  height: 50px;
  background: #5CAE48;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.btn-submit:hover {
  background: #0e3453;
  color: #fff;
}
.btn-download {
  background: transparent;
  border: 2px solid #000000;
  border-radius: 4px;
  height: 50px;
  display: inline-flex;
  padding: 0 25px;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #070707;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.btn-download i {
  background: url(../images/download.svg) no-repeat 0 0/100%;
  width: 19px;
  height: 19px;
  margin-right: 9px;
}
.btn-download:hover {
  background: #070707;
  border-color: #070707;
  color: #fff;
}
.btn-download:hover i {
  filter: invert(1);
  -webkit-filter: invert(1);
}
.btn-white {
  background: #FFFFFF;
  border-radius: 4px;
  height: 50px;
  padding: 0 25px;
  line-height: 50px;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.btn-white .right-arrow {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3e%3c/svg%3e");
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-left: 25px;
  margin-top: 15px;
  vertical-align: top;
  transition: all 0.3s ease-in-out;
}
.btn-white:hover {
  background-color: #a8760b;
  color: #fff;
}
.btn-white:hover span {
  filter: invert(1) brightness(170%);
  -webkit-filter: invert(1) brightness(170%);
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
}

.template-date-table {
  margin-top: 20px;
}

.template-date-table .table-responsive > table.table > thead th {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #0B3454;
  border: 0;
  background-color: transparent;
}

.template-date-table .table-responsive > table.table > :not(:last-child) > :last-child > * {
  border-bottom-color: transparent;
}

.template-date-table .table-responsive > table.table td {
  position: relative;
  border: 0;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
}

.template-date-table .table-responsive > table.table td b {
  font-weight: 500;
}

.template-date-table .table-responsive > table.table tbody tr {
  padding: 10px 0;
}

.template-date-table .table-responsive > table.table td:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 0px;
  margin: auto;
  background: #fff;
  right: 0;
}

.template-date-table .table-responsive > table.table tr.inactive td::before {
  background: #FFEAEA;
}

.template-date-table .table-responsive > table.table td:first-child:before {
  border-radius: 6px 0 0 6px;
}

.template-date-table .table-responsive > table.table td:last-child:before {
  border-radius: 0 6px 6px 0;
}

.template-date-table .table-responsive > table.table td div {
  position: relative;
}

.btn-delete {
  border: 1px solid rgba(11, 52, 84, 0.2);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  position: relative;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.btn-delete:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 13px;
  height: 13px;
  background: url(../images/bin.svg) no-repeat center/13px;
  right: 0;
}

.btn-delete:hover {
  background: rgba(11, 52, 84, 0.2);
}

ul.listed-points {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}

ul.listed-points li {
  position: relative;
  font-size: 18px;
  padding-left: 24px;
  margin-bottom: 6px;
  max-width: 550px;
  width: 100%;
}

ul.listed-points li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #000;
}

.btn-save {
  background: #FFFFFF;
  border: 1px solid #0B3454;
  border-radius: 4px;
  width: 120px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.btn-save:hover {
  background: #0B3454;
  color: #fff;
}

.btn-preview {
  background: #0B3454;
  border-radius: 4px;
  width: 120px;
  height: 40px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.btn-preview:hover {
  background: #095FA2;
  color: #fff;
}

.btn-publish {
  width: 120px;
  height: 40px;
  background: #5CAE48;
  border-radius: 4px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.btn-publish:hover {
  background: #247111;
  color: #fff;
}

.btn-active {
  width: 120px;
  height: 40px;
  background: #5CAE48;
  border-radius: 4px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.btn-active:hover {
  background: #247111;
  color: #fff;
}

.btn-deactive {
  width: 120px;
  height: 40px;
  background: #D2D2D2;
  border-radius: 4px;
  color: #333;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.btn-deactive:hover {
  background: #6f6f6f;
  color: #fff;
}

.btn-addTemplate {
  background: #0B3454;
  border-radius: 4px;
  padding: 0.375rem 20px;
  height: 40px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.btn-addTemplate:hover {
  background: #095FA2;
  color: #fff;
}

.success-template {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.success-template .template_selection_link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-template .template_selection_link a {
  margin: 0 10px;
  text-decoration: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.success-template .template_selection_link a:hover {
  background: #57ae43;
  color: #fff;
}

.btn-save {
  background: #FFFFFF;
  border: 1px solid #0B3454;
  border-radius: 4px;
  width: 120px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.btn-save:hover {
  background: #0B3454;
  color: #fff;
}

.btn-preview {
  background: #0B3454;
  border-radius: 4px;
  width: 120px;
  height: 40px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.btn-preview:hover {
  background: #095FA2;
  color: #fff;
}

.btn-publish {
  width: 120px;
  height: 40px;
  background: #5CAE48;
  border-radius: 4px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.btn-publish:hover {
  background: #247111;
  color: #fff;
}

.btn-active {
  width: 120px;
  height: 40px;
  background: #5CAE48;
  border-radius: 4px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.btn-active:hover {
  background: #247111;
  color: #fff;
}

.btn-deactive {
  width: 120px;
  height: 40px;
  background: #D2D2D2;
  border-radius: 4px;
  color: #333;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.btn-deactive:hover {
  background: #6f6f6f;
  color: #fff;
}

.btn-addTemplate {
  background: #0B3454;
  border-radius: 4px;
  padding: 0.375rem 20px;
  height: 40px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.btn-addTemplate:hover {
  background: #095FA2;
  color: #fff;
}

.input-group.video-link {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 1;
  width: auto;
  margin: auto;
  background: #F4FBFE 0% 0% no-repeat padding-box;
  border: 1px solid rgba(11, 52, 84, 0.3019607843);
  border-radius: 4px;
  height: 40px;
}

.videoSection {
  position: relative;
}

.input-group.video-link .input-group-text {
  height: 100%;
  background: #F4FBFE;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 10px;
  color: #000;
}

.input-group.video-link .form-control {
  height: 100%;
  border: none;
}

.input-group.video-link .input-group-text i {
  margin-right: 10px;
}

#template_one .leftPaneImg {
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
}

.template-edit-section-body .upload-img {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  width: 215px;
  height: 40px;
  justify-content: center;
  margin-bottom: 20px;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.input-group.video-link + .upload-img {
  bottom: 70px;
}

.template-edit-section-body .upload-img .btn-browse i {
  display: flex;
  align-items: center;
}

.blue_curve .curve-img .upload-img {
  bottom: 140px;
}

.blue_curve.right .curve-img .upload-img {
  bottom: 90px;
}

.success-template {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.success-template .template_selection_link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-template .template_selection_link a {
  margin: 0 10px;
  text-decoration: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.success-template .template_selection_link a:hover {
  background: #57ae43;
  color: #fff;
}

.iframe-preview {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.iframe-preview.iframe-mobile {
  max-width: 400px;
}

/* Custom Scrollbar using CSS */
.iframe-preview {
  overflow-y: scroll;
}

/* scrollbar width */
.iframe-preview::-webkit-scrollbar {
  width: 5px;
}

/* scrollbar track */
.iframe-preview::-webkit-scrollbar-track {
  background: #eee;
}

/* scrollbar handle */
.iframe-preview::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #333;
}

#template-preview > div {
  background: none;
}

.togglelinks {
  margin: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  max-width: 320px;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  border: 1px solid #0B3454;
}

.togglelinks a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0B3454;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.togglelinks a.btn-desktop i {
  width: 24px;
  height: 21px;
  background: url(../images/desktop-monitor.svg);
  margin-right: 10px;
}

.togglelinks a.btn-mobile i {
  width: 14px;
  height: 24px;
  background: url(../images/smartphone.svg);
  margin-right: 10px;
}

.togglelinks a.active {
  background: #0b3454;
  color: #fff;
}

.togglelinks a.active i, .togglelinks a:hover i {
  filter: invert(1) brightness(170%);
}

.togglelinks a:hover {
  background: #294a64;
  color: #fff;
}

.btn-add-fold {
  background: #26A7DF;
  border-radius: 4px;
  border: 0;
  width: 140px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.btn-add-fold:hover {
  background: #0b3454;
}

.blogtab {
  overflow: auto;
  flex-wrap: nowrap;
}
@media (max-width: 575px) {
  .blogtab {
    padding-bottom: 20px;
  }
}
.blogtab .nav-item:not(:last-child) {
  margin-right: 10px;
}
.blogtab .nav-link {
  border-radius: 25px;
  padding: 10px 25px;
  font-size: 18px;
  background: white;
  box-shadow: 0 0 5px 0 rgba(183, 183, 183, 0.1882352941);
  color: #5e5e5e;
  white-space: nowrap;
}
.blogtab .nav-link.active, .blogtab .nav-link:hover {
  background-color: #5CAE48;
  color: #fff;
}

/* template edits */
.template-edit-section {
  max-width: 1366px;
  margin: 60px auto;
}

.template-edit-section-body {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.0784313725);
}

.template-edit-section-body header {
  background-color: #fff;
  position: relative;
}

#template_one .caption .caption-edit {
  font-weight: 800;
  font-size: 80px;
  line-height: 85px;
  letter-spacing: 0;
  background: none;
  color: #fff;
  text-align: center;
  max-width: 740px;
  box-sizing: border-box;
  border: 2px solid transparent;
  word-break: break-all;
  padding: 0px 0 15px;
  margin-bottom: -15px;
}

#template_one .caption .caption-edit:focus {
  border: 2px solid #26a7df;
}

#template_one .caption .caption-edit:hover {
  border: 2px solid #26a7df;
}

.template-edit-section-body .caption .caption-edit {
  font-weight: 800;
  font-size: 80px;
  line-height: 85px;
  letter-spacing: 0;
  background: none;
  color: #fff;
  text-align: center;
  max-width: 740px;
  box-sizing: border-box;
  border: 2px solid transparent;
  word-break: break-all;
  padding: 0px 0 15px;
  margin-bottom: -15px;
}

.template-edit-section-body .caption .caption-edit:focus {
  border: 2px solid #fff;
}

.template-edit-section-body .caption .caption-edit:hover {
  border: 2px solid #fff;
}

.template-edit-section-body .caption .caption-edit:hover::after, .impact-created h2[contenteditable]:hover::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: -25px;
  right: -2px;
  background: white url(../images/editing.svg) no-repeat center;
  padding: 12px;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#template_one .caption span.sub-caption {
  border: 2px solid transparent;
  line-height: 40px;
  margin-bottom: 20px;
}

#template_one .caption .sub-caption:focus {
  border: 2px solid #26a7df;
}

#template_one .caption .sub-caption:hover {
  border: 2px solid #26a7df;
}

.template-edit-section-body .caption span.sub-caption {
  border: 2px solid transparent;
  line-height: 40px;
  margin-bottom: 20px;
}

.template-edit-section-body .caption .sub-caption:focus {
  border: 2px solid #fff;
}

.template-edit-section-body .caption .sub-caption:hover {
  border: 2px solid #fff;
}

#template_one .page-title h1.title, #template_one .page-title p.sub-tite {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#template_one .page-title h1.title div, #template_one .page-title p.sub-tite span {
  border: 2px solid transparent;
}

#template_one .page-title h1.title div:focus, #template_one .page-title p.sub-tite span:focus {
  border: 2px solid #26a7df;
}

#template_one .page-title h1.title div:hover, #template_one .page-title p.sub-tite span:hover {
  border: 2px solid #26a7df;
}

p[contenteditable] {
  border: 2px solid transparent;
}

p[contenteditable]:focus {
  border: 2px solid #26a7df;
}

p[contenteditable]:hover {
  border: 2px solid #26a7df;
}

[contenteditable] {
  border: 2px solid transparent;
  position: relative;
}

[contenteditable]:focus {
  border: 2px solid #26a7df;
}

[contenteditable]:hover {
  border: 2px solid #26a7df;
}

[contenteditable]:hover::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: -25px;
  right: -2px;
  background: #26a7df url(../images/editing-white.svg) no-repeat center;
  padding: 12px;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#template_one .caption .caption-edit[contenteditable]:hover::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: -25px;
  right: -2px;
  background: #26a7df url(../images/editing-white.svg) no-repeat center;
  padding: 12px;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.impact-created h2[contenteditable] {
  border: 2px solid transparent;
}

.impact-created h2[contenteditable]:focus {
  border: 2px solid #fff;
}

.impact-created h2[contenteditable]:hover {
  border: 2px solid #fff;
}

.rightpane h2[contenteditable] {
  border: 2px solid transparent;
}

.rightpane h2[contenteditable]:focus {
  border: 2px solid #26a7df;
}

.rightpane h2[contenteditable]:hover {
  border: 2px solid #26a7df;
}

.rightpane ul[contenteditable] {
  border: 2px solid transparent;
}

.rightpane ul[contenteditable]:focus {
  border: 2px solid #26a7df;
}

.rightpane ul[contenteditable]:hover {
  border: 2px solid #26a7df;
}

.btn-green[contenteditable] {
  border: 2px solid #5CAE48;
}

.btn-green[contenteditable]:focus {
  border: 2px solid #26a7df;
}

.btn-green[contenteditable]:hover {
  border: 2px solid #26a7df;
}

.rightpane .btn-download span[contenteditable] {
  border: 2px solid transparent;
}

.rightpane .btn-download span[contenteditable]:focus {
  border: 2px solid #26a7df;
}

.rightpane .btn-download span[contenteditable]:hover {
  border: 2px solid #26a7df;
}

h1[contenteditable] {
  border: 2px solid transparent;
}

h1[contenteditable]:focus {
  border: 2px solid #26a7df;
}

h1[contenteditable]:hover {
  border: 2px solid #26a7df;
}

h2[contenteditable] {
  border: 2px solid transparent;
}

h2[contenteditable]:focus {
  border: 2px solid #26a7df;
}

h2[contenteditable]:hover {
  border: 2px solid #26a7df;
}

.sub-title[contenteditable] {
  border: 2px solid transparent;
}

.sub-title[contenteditable]:focus {
  border: 2px solid #26a7df;
}

.sub-title[contenteditable]:hover {
  border: 2px solid #26a7df;
}

.btn-white span[contenteditable] {
  border: 2px solid #fff;
}

.btn-white span[contenteditable]:focus {
  border: 2px solid #26a7df;
}

.btn-white span[contenteditable]:hover {
  border: 2px solid #26a7df;
}

.red_bg_text[contenteditable] {
  border: 2px solid transparent;
}

.red_bg_text[contenteditable]:focus {
  border: 2px solid #26a7df;
}

.red_bg_text[contenteditable]:hover {
  border: 2px solid #26a7df;
}

ul.arrow-list[contenteditable] {
  border: 2px solid transparent;
  margin-bottom: 10px !important;
}

ul.arrow-list[contenteditable]:focus {
  border: 2px solid #26a7df;
}

ul.arrow-list[contenteditable]:hover {
  border: 2px solid #26a7df;
}

.btn-download-green span[contenteditable] {
  border: 2px solid transparent;
}

.btn-download-green span[contenteditable]:focus {
  border: 2px solid #26a7df;
}

.btn-download-green span[contenteditable]:hover {
  border: 2px solid #26a7df;
}

.template-edit-section .form-control {
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid rgba(112, 112, 112, 0.2509803922);
  border-radius: 4px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

#template_one .caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.template-edit-section-body .browse-image {
  background: #fff;
  max-width: 400px;
  border-radius: 4px;
  line-height: 18px;
  font-size: 16px;
  color: #000;
  padding: 20px 30px;
  margin-top: 40px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.template-edit-section-body .browse-image img {
  width: 16px;
}

.template-edit-section-body .btn-browse {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pageHeader.edit-header {
  padding: 20px 0 20px;
  margin: 0;
  border-bottom: 1px solid rgba(11, 52, 84, 0.1490196078);
}

.pageHeader h1 {
  font-size: 32px;
  margin: 5px 0;
  font-weight: 600;
}

.btn.btn-save {
  background: #FFFFFF;
  border: 1px solid #0B3454;
  border-radius: 4px;
  width: 120px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.btn.btn-save:hover {
  background: #0B3454;
  color: #fff;
}
.btn.btn-preview {
  background: #0B3454;
  border-radius: 4px;
  width: 120px;
  height: 40px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.btn.btn-preview:hover {
  background: #095FA2;
  color: #fff;
}
.btn.btn-publish {
  width: 120px;
  height: 40px;
  background: #5CAE48;
  border-radius: 4px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}
.btn.btn-publish:hover {
  background: #247111;
  color: #fff;
}
.btn.btn-active {
  width: 120px;
  height: 40px;
  background: #5CAE48;
  border-radius: 4px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}
.btn.btn-active:hover {
  background: #247111;
  color: #fff;
}
.btn.btn-deactive {
  width: 120px;
  height: 40px;
  background: #D2D2D2;
  border-radius: 4px;
  color: #333;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}
.btn.btn-deactive:hover {
  background: #6f6f6f;
  color: #fff;
}
.btn.btn-addTemplate {
  background: #0B3454;
  border-radius: 4px;
  padding: 0.375rem 20px;
  height: 40px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.btn.btn-addTemplate:hover {
  background: #095FA2;
  color: #fff;
}

.input-group.video-link {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 1;
  width: auto;
  margin: auto;
  background: #F4FBFE 0% 0% no-repeat padding-box;
  border: 1px solid rgba(11, 52, 84, 0.3019607843);
  border-radius: 4px;
  height: 40px;
}

.videoSection {
  position: relative;
}

.input-group.video-link .input-group-text {
  height: 100%;
  background: #F4FBFE;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 10px;
  color: #000;
}

.input-group.video-link .form-control {
  height: 100%;
  border: none;
}

.input-group.video-link .input-group-text i {
  margin-right: 10px;
}

#template_one .leftPaneImg {
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
}

.template-edit-section-body .upload-img {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  width: 215px;
  height: 40px;
  justify-content: center;
  margin-bottom: 20px;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.input-group.video-link + .upload-img {
  bottom: 70px;
}

.template-edit-section-body .upload-img .btn-browse i {
  display: flex;
  align-items: center;
}

.blue_curve .curve-img .upload-img {
  bottom: 140px;
}

.blue_curve.right .curve-img .upload-img {
  bottom: 90px;
}

.success-template {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.success-template .template_selection_link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-template .template_selection_link a {
  margin: 0 10px;
  text-decoration: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.success-template .template_selection_link a:hover {
  background: #57ae43;
  color: #fff;
}

.iframe-preview {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.iframe-preview.iframe-mobile {
  max-width: 400px;
}

/* Custom Scrollbar using CSS */
.iframe-preview {
  overflow-y: scroll;
}

/* scrollbar width */
.iframe-preview::-webkit-scrollbar {
  width: 5px;
}

/* scrollbar track */
.iframe-preview::-webkit-scrollbar-track {
  background: #eee;
}

/* scrollbar handle */
.iframe-preview::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #333;
}

.togglelinks {
  margin: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  max-width: 320px;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  border: 1px solid #0B3454;
}

.togglelinks a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0B3454;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.togglelinks a.btn-desktop i {
  width: 24px;
  height: 21px;
  background: url(../images/desktop-monitor.svg);
  margin-right: 10px;
}

.togglelinks a.btn-mobile i {
  width: 14px;
  height: 24px;
  background: url(../images/smartphone.svg);
  margin-right: 10px;
}

.togglelinks a.active {
  background: #0b3454;
  color: #fff;
}

.togglelinks a.active i, .togglelinks a:hover i {
  filter: invert(1) brightness(170%);
}

.togglelinks a:hover {
  background: #294a64;
  color: #fff;
}

.modal-dialog.max-1366 {
  max-width: 1366px;
}

.modal-dialog.max-1016 {
  max-width: 1016px;
}

.template-date-table {
  margin-top: 40px;
}

.template-date-table .table-responsive > table.table > thead th {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #0B3454;
  border: 0;
}

.template-date-table .table-responsive > table.table > :not(:last-child) > :last-child > * {
  border-bottom-color: transparent;
}

.template-date-table .table-responsive > table.table td {
  position: relative;
  border: 0;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
}

.template-date-table .table-responsive > table.table td b {
  font-weight: 500;
}

.template-date-table .table-responsive > table.table tbody tr {
  padding: 10px 0;
}

.template-date-table .table-responsive > table.table td:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 0px;
  margin: auto;
  background: #fff;
  right: 0;
}

.template-date-table .table-responsive > table.table tr.inactive td::before {
  background: #FFEAEA;
}

.template-date-table .table-responsive > table.table td:first-child:before {
  border-radius: 6px 0 0 6px;
}

.template-date-table .table-responsive > table.table td:last-child:before {
  border-radius: 0 6px 6px 0;
}

.template-date-table .table-responsive > table.table td div {
  position: relative;
}

.btn-delete {
  border: 1px solid rgba(11, 52, 84, 0.2);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  position: relative;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.btn-delete:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 13px;
  height: 13px;
  background: url(../images/bin.svg) no-repeat center/13px;
  right: 0;
}

.btn-delete:hover {
  background: rgba(11, 52, 84, 0.2);
}

ul.listed-points {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}

ul.listed-points li {
  position: relative;
  font-size: 18px;
  padding-left: 24px;
  margin-bottom: 6px;
  max-width: 550px;
  width: 100%;
}

ul.listed-points li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #000;
}

.added-img {
  max-width: 500px;
  width: 100%;
  min-height: 350px;
  margin: auto;
  background: #F4F6F9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.fold-section {
  background: #fff;
  margin-bottom: 30px;
}

.fold-section-ui {
  padding: 30px 113px;
}

.added-img .img-options {
  position: absolute;
  bottom: 20px;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}

.added-img .img-options .img-upload {
  margin-right: 10px;
  background: #fff;
  padding: 0 13px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.added-img .img-options .img-upload .btn-browse {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.added-img .img-options .img-upload .btn-browse i {
  display: flex;
}

.img-alignment .form-select {
  border: 0;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='23343a40' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='0' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e");
  background-size: 15px;
}

.added-img .img-options .img-upload .upload-img {
  margin-bottom: 0;
}

.added-video {
  max-width: 500px;
  width: 100%;
  min-height: 350px;
  margin: auto;
  background: #F4F6F9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.fold-section-ui h1 {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 26px;
}

.fold-section-ui p {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 20px;
}

.btn-green {
  text-decoration: none;
}

.fold-wrap .fold-section {
  margin: 0;
  position: relative;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.fold-wrap .fold-section .btn-danger {
  position: absolute;
  top: 20px;
  right: 20px;
  right: -160px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.fold-wrap .fold-section:hover .btn-danger {
  right: 20px;
  opacity: 1;
}

.fold-wrap .fold-section .btn-danger span {
  padding: 0;
  margin: 0 5px 0 0;
  width: 10px;
  height: 10px;
  display: inline-block;
}

[data-img] {
  position: relative;
}

/* [data-img]:after {content: 'images diamentions required ' attr(data-img);position: absolute;z-index: 1;top: 0;right: 0;background: #0b3454;padding: 5px 10px;border-radius: 0 8px;color: #fff;} */
[data-img]::after {
  content: "images diamentions required " attr(data-img);
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 0;
  background: rgb(38, 169, 225);
  padding: 10px 10px;
  border-radius: 2px;
  color: #fff;
  margin: auto;
  left: 0;
  text-align: center;
  font-size: 14px;
  max-width: 310px;
}

.listImg[data-img]::after, .logo [data-img]::after, .recent_stories_thumb[data-img]::after, .success-img[data-img]::after, #navbarSupportedContent [data-img]::after {
  display: none;
}

.template-edit-section .mainbanner.realGullyBoyBanner {
  min-height: 600px;
}

.fold-links {
  background: #F4FBFD;
  padding: 20px 30px;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  border-top: 5px solid #26A7DF;
  position: relative;
}

.fold-links::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 0px solid #26a7df;
  right: 40px;
  bottom: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.fold-section.show .fold-links::before {
  border-bottom: 18px solid #26a7df;
}

.fold-links label {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #0B3454;
  border-radius: 4px;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 15px 8px 20px;
  cursor: pointer;
}

.fold-links label .icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fold-links .link-options:not(:last-child) {
  margin-right: 17px;
}

.fold-links label i {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #CED6DD;
  position: relative;
  margin-left: auto;
}

.fold-links label span {
  margin-right: 20px;
}

.fold-links label i:before {
  content: "";
  position: absolute;
  left: 3.6px;
  top: 4px;
  width: 7px;
  height: 4px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.fold-links input:checked + label {
  border-color: rgba(92, 174, 72, 0.5019607843);
}

.fold-links input:checked + label i {
  background: #5CAE48;
}

#template-preview .modal-fullscreen .modal-header {
  border-radius: 0;
  box-shadow: 0 0 4px 2px #ccc;
  z-index: 1;
}

.select-category {
  background: #fff;
  padding: 0;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  border-top: 0px solid #26A7DF;
  position: relative;
}

.select-category label {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 15px 5px 15px;
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px #F1F1F1;
  border: 1px solid #DEDEDE;
  border-radius: 7px;
  font-size: 14px;
}

.select-category label .icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-category .link-options:not(:last-child) {
  margin-right: 10px;
}

.select-category label i {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #CED6DD;
  position: relative;
  margin-left: auto;
}

.select-category label span {
  margin-right: 10px;
}

.select-category label i:before {
  content: "";
  position: absolute;
  left: 3.6px;
  top: 4px;
  width: 7px;
  height: 4px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.select-category input:checked + label {
  border-color: #5CAE48;
}

.select-category input:checked + label i {
  background: #5CAE48;
}

.fold-section-ui h1[contentEditable=true], .fold-section-ui p[contentEditable=true], ul.listed-points[contentEditable=true] {
  border: 2px solid transparent;
}

.fold-section-ui h1[contentEditable=true]:focus, .fold-section-ui p[contentEditable=true]:focus, ul.listed-points[contentEditable=true]:focus {
  border: 2px solid #26a7df;
}

.fold-section-ui h1[contentEditable=true]:hover, .fold-section-ui p[contentEditable=true]:hover {
  border: 2px solid #26a7df;
}

.btn-green[contentEditable=true]:focus {
  border: 2px solid #26a7df;
}

.btn-green[contentEditable=true]:hover {
  border: 2px solid #26a7df;
}

.btn-addTemplate[contentEditable=true]:focus {
  border: 2px solid #26a7df;
}

.btn-addTemplate[contentEditable=true]:hover {
  border: 2px solid #26a7df;
}

.rightpane h3[contentEditable=true] {
  border: 2px solid transparent;
}

.rightpane h3[contentEditable=true]:focus {
  border: 2px solid #26a7df;
}

.rightpane h3[contentEditable=true]:hover {
  border: 2px solid #26a7df;
}

.rightSideForm.rightGraph h3[contentEditable=true] {
  border: 2px solid transparent;
}

.rightSideForm.rightGraph h3[contentEditable=true]:focus {
  border: 2px solid #26a7df;
}

.rightSideForm.rightGraph h3[contentEditable=true]:hover {
  border: 2px solid #26a7df;
}

.selected-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}

.selected-items ul li {
  background: #FFFFFF;
  box-shadow: 0px 1px 1px #F1F1F1;
  border: 1px solid #DEDEDE;
  border-radius: 7px;
  font-size: 14px;
  padding: 0 15px;
  height: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.selected-items ul li:not(:last-child) {
  margin-right: 5px;
}

.selected-items ul li .selection-cancel {
  margin-left: 5px;
  background: #989898;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  line-height: 12px;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
}

.modal-content .form-control {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #848484;
  border-radius: 6px;
  height: 45px;
}

.modal-content textarea.form-control {
  height: 80px;
}

.fixed-fold-section {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 680px;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(179, 179, 179, 0.5019607843);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  z-index: 1;
}

.fixed-fold-section .text-fold {
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
}

#template_two .realGullyBoy h1 {
  font-size: 60px;
  font-weight: 800;
  line-height: 66px;
}

#template_two .realGullyBoy .sub-title {
  font-size: 22px;
  line-height: 26px;
}

#template_two .realGullyBoy p {
  line-height: 22px;
  font-size: 16px;
}

.fileclose {
  height: 12px;
  line-height: 10px;
  width: 12px;
  text-align: center;
  background: #959595;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  display: none;
  margin-left: 6px;
  justify-content: center;
  align-items: center;
}

.fileclose span {
  font-size: 6px;
  display: flex;
  margin: 0 0 0px 1px;
  justify-content: center;
  align-items: center;
}

.fileclose span::before {
  content: "X";
  font-size: 8px;
  font-weight: 500;
}

.fileicon {
  display: none;
  margin-right: 6px;
}

.docselect {
  display: none;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
}

.docselect.docseladdbg {
  background: #f8f8f8;
  display: -webkit-flex;
  padding: 7px 12px;
  margin: 10px 0 0 0;
  border-radius: 6px;
}

.inputfile::file-selector-button {
  font: inherit;
  display: none;
}

.inputfile_parent label {
  color: #fff;
  height: 45px;
  max-width: 100%;
  font-size: 16px;
  font-weight: 500;
  width: 180px;
  background: #0b3454;
  border: 0.5px dashed #0b3454;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inputfile_parent label i {
  display: flex;
  align-items: center;
  filter: invert(1);
  -webkit-filter: invert(1);
}

.inputfile_parent label span {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  font-style: unset;
  float: none;
  padding: 0 0 0 10px;
}

.inputfile_parent label span.text-green {
  color: #5cae48;
  text-decoration: underline;
  padding: 0;
  font-weight: 500;
}

.inputfile_parent {
  position: relative;
  height: auto;
  margin-top: 20px;
}

.inputfile_parent.profile .docselect {
  display: none;
}

.inputfile_parent.profile.select + p {
  display: none;
}

.inputfile_parent.profile.select .docseladdbg {
  display: flex;
  background: #ddd;
}

.inputfile_parent.profile.pop-up label {
  background: #f3f3f3;
  border: 1px dashed #848484;
  border-radius: 6px;
  opacity: 1;
  height: 45px;
  line-height: 45px;
}

.template-edit-section-body .left-dot {
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
}

.template-edit-section-body .beigeSectionImg {
  position: relative;
}

.btn-customize {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #0B3454;
}

.btn-customize i {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../images/editing.svg) no-repeat 0 0;
  margin-right: 10px;
  margin-top: 2px;
  vertical-align: top;
}

.btn-customize:hover {
  color: #5cae48;
}

.btn-customize:hover i {
  filter: invert(61%) sepia(19%) saturate(1247%) hue-rotate(63deg) brightness(94%) contrast(89%);
  -webkit-filter: invert(61%) sepia(19%) saturate(1247%) hue-rotate(63deg) brightness(94%) contrast(89%);
}

.bg-yellow .btn-customize:hover {
  color: #fff;
}

.bg-yellow .btn-customize:hover i {
  filter: invert(0) sepia(19%) saturate(1247%) hue-rotate(63deg) brightness(100) contrast(89%);
  -webkit-filter: invert(0) sepia(19%) saturate(1247%) hue-rotate(63deg) brightness(100) contrast(89%);
}

.paneBody {
  padding: 40px;
}

.paneHeader {
  border-bottom: 1px solid #E2E2E2;
  padding: 20px 40px 15px;
  font-size: 24px;
  font-weight: bold;
}

.leftPane {
  margin-right: calc(var(--bs-gutter-x) * -0.5);
}

.rightPane {
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  padding: 40px;
}

.rightPaneHeader {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

.paneBody .form-label {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 3px;
}

.input-group-text {
  background: #26A7DF 0% 0% no-repeat padding-box;
  border-radius: 6px 0px 0px 6px;
  border: 0;
  color: #fff;
  height: 45px;
  padding-left: 15px;
  padding-right: 15px;
}

.paneBody .input-group span {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #848484;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-left: 0;
}

.paneBody .input-group-text i {
  display: flex;
  margin-right: 10px;
  filter: invert(1);
  align-items: center;
  -webkit-filter: invert(1);
}

.paneBody .btn-addTemplate {
  height: 50px;
}

.template-edit-section-body .blue_curve_detail {
  max-width: 475px;
}

.template-edit-section-body .template_4 {
  background-position: -120px -89px, top -25px right;
}

.darkoption label {
  line-height: 18px;
  vertical-align: top;
  margin: 6px 0 0;
  cursor: pointer;
}

.darkoption {
  line-height: 18px;
}

.darkoption input {
  cursor: pointer;
}

.modal-backdrop.fade.show + .show {
  z-index: 9999;
}

.modal.show ~ .modal.show {
  z-index: 99999;
}

@media (min-width: 1300px) {
  table.pg-smart > thead th:nth-child(3) {
    position: sticky;
    left: 300px;
    width: 230px;
    background: #fff;
    z-index: 2;
  }
  table.pg-smart > thead th:nth-child(4) {
    position: sticky;
    left: 530px;
    width: 180px;
    background: #fff;
    z-index: 2;
  }
  table.pg-smart > thead th:nth-child(5) {
    padding-left: 30px;
  }
  table.pg-smart > tbody td:nth-child(3) {
    position: sticky;
    left: 300px;
    background: #fff;
    width: 230px;
    z-index: 2;
  }
  table.pg-smart > tbody td:nth-child(4) {
    position: sticky;
    left: 530px;
    background: #fff;
    width: 180px;
    z-index: 2;
  }
  table.pg-smart > tbody td:nth-child(5) {
    padding-left: 30px;
  }
  table.pg-smart th:nth-child(4)::after {
    content: "";
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #000000 100%);
    opacity: 0.1;
    width: 30px;
    position: absolute;
    right: -30px;
    bottom: 0;
    top: 0;
  }
  table.pg-smart td:nth-child(4)::after {
    content: "";
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #000000 100%);
    opacity: 0.1;
    width: 30px;
    position: absolute;
    right: -30px;
    bottom: 0;
    top: 0;
  }
}
@media (max-width: 1299px) {
  table.pg-smart > thead th:nth-child(3) {
    padding-left: 30px;
  }
  table.pg-smart > tbody td:nth-child(3) {
    padding-left: 30px;
  }
  table.pg-smart th:nth-child(2)::after {
    content: "";
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #000000 100%);
    opacity: 0.1;
    width: 30px;
    position: absolute;
    right: -30px;
    bottom: 0;
    top: 0;
  }
  table.pg-smart td:nth-child(2)::after {
    content: "";
    background: transparent linear-gradient(270deg, #FFFFFF 0%, #000000 100%);
    opacity: 0.1;
    width: 30px;
    position: absolute;
    right: -30px;
    bottom: 0;
    top: 0;
  }
}
@media (max-width: 1199px) {
  .summary-blocks .block {
    padding: 15px 15px;
  }
  .template-date-table .table {
    max-width: 1024px;
    width: 1024px;
  }
}
@media (max-width: 767px) {
  .order-mobile-first {
    order: -1;
  }
  .filterResult ul li span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 24px);
    font-size: 14px;
  }
}
@media (max-width: 435px) {
  .order-mobile-first {
    order: -1;
  }
  .filterResult + div {
    flex-direction: column;
    align-items: start !important;
  }
  .filterResult + div > div:first-child {
    margin-bottom: 20px;
  }
  select.form-control#selectbox {
    max-width: 85px;
  }
}
.tagify__input[contenteditable]:hover {
  border: 0;
}

.tagify__input[contenteditable]:hover:after {
  opacity: 0;
}

.tagify__input[contenteditable]:focus {
  border: 0;
}

.thumb-wrap {
  display: flex;
  align-items: center;
}

#thumb-image .file-text {
  display: none;
}

.thumb-image.select .default-text {
  display: none;
}

.thumb-image.select .file-text {
  display: block;
}

.thumb-image {
  padding: 0 20px;
  background: #0b3454;
  border-radius: 3px;
  min-height: 48px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  max-width: 230px;
  cursor: pointer;
}

.thumb-image i {
  filter: invert(1) brightness(1);
  display: flex;
  align-items: center;
  width: 20px;
}

.thumb-image input {
  display: none;
}

input::file-selector-button {
  display: none;
}

input[type=file]::-webkit-file-upload-button {
  display: none;
}

label.thumb-image.select input {
  display: block;
}

label.thumb-image.select + a {
  width: 20px;
  height: 20px;
  border: 2px solid #919191;
  display: inline-block;
  border-radius: 50%;
  margin-left: 4px;
  position: relative;
  transform: rotate(45deg);
}

label.thumb-image.select + a:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 2px;
  background: #999;
}

label.thumb-image.select + a:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 8px;
  background: #999;
}

.bg-darkblue {
  background: #0B3454;
}

#customize-form .modal-content {
  overflow: hidden;
}
#customize-form .modal-content .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.search-tab {
  justify-content: center;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .search-tab {
    justify-content: start;
    margin: 0 20px;
  }
}
.search-tab .nav-item:not(:last-child) {
  margin-right: 10px;
}
.search-tab .nav-item .nav-link {
  padding: 0 5px;
  background: none;
  color: #000;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}
.search-tab .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  margin: auto;
  width: 0;
  height: 3px;
  background-color: #d42941;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.search-tab .nav-item .nav-link:hover::before, .search-tab .nav-item .nav-link.active::before {
  width: 100%;
}

.searchWrap {
  width: 100%;
  height: 62px;
  margin: 0px auto;
  box-shadow: 0 3px 12px -9px rgba(0, 0, 0, 0.4);
  border: 1px solid #909090;
  background-color: #ffffff;
  display: flex;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  align-items: center;
  padding: 9px;
  position: relative;
}
@media (max-width: 575px) {
  .searchWrap {
    height: auto;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}
.searchWrap .inputWrap {
  width: calc(100% - 107px);
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 10px;
  height: 46px;
}
@media (max-width: 699px) {
  .searchWrap .inputWrap {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .searchWrap .inputWrap {
    height: auto;
  }
}
.searchWrap .inputWrap .form-control, .searchWrap .inputWrap .form-select {
  height: 100%;
  border: none;
  border-right: 1px solid #ccc;
  border-radius: 0;
}
.searchWrap .inputWrap .searchInput {
  width: 40%;
}
.searchWrap .inputWrap .searchInput .form-control {
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="%23d42941" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/%3e%3c/svg%3e);
  background-image: var(--bs-form-select-bg-img);
  background-position: left 15px center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding-left: 40px;
  border: none;
  border-right: 1px solid #ccc;
  border-radius: 0;
  font-size: 18px;
}
.searchWrap .inputWrap .searchInput .form-control:focus {
  outline: none;
}
@media (max-width: 575px) {
  .searchWrap .inputWrap .searchInput .form-control {
    border-right: 0px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}
@media (max-width: 575px) {
  .searchWrap .inputWrap .searchInput {
    width: 100%;
  }
}
.searchWrap .inputWrap .searchSelect {
  width: 30%;
}
@media (max-width: 575px) {
  .searchWrap .inputWrap .searchSelect {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .searchWrap .inputWrap .searchSelect {
    width: 100%;
  }
}
.searchWrap .inputWrap .searchSelect .property-type {
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="%23d42941" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5"/%3e%3c/svg%3e);
  background-image: var(--bs-form-select-bg-img);
  background-position: left 15px center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding-left: 45px;
  border: none;
  border-right: 1px solid #ccc;
  border-radius: 0;
  padding-right: 0;
  font-size: 18px;
}
.searchWrap .inputWrap .searchSelect .property-type:focus {
  outline: none;
}
@media (max-width: 480px) {
  .searchWrap .inputWrap .searchSelect .property-type {
    border-right: 0px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}
.searchWrap .inputWrap .searchSelect .property-budget {
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="%23d42941" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/%3e%3c/svg%3e);
  background-image: var(--bs-form-select-bg-img);
  background-position: left 15px center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding-left: 45px;
  border: none;
  border-right: 0px solid #ccc;
  border-radius: 0;
  padding-right: 0;
  font-size: 18px;
}
.searchWrap .inputWrap .searchSelect .property-budget:focus {
  outline: none;
}
.searchWrap .btn-search {
  background: #d42941;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  height: 46px;
  color: #FFF;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.searchWrap .btn-search i {
  background-image: url(../images/search.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 0px center;
  width: 14px;
  height: 14px;
  filter: brightness(1) invert(1);
  margin-right: 5px;
}
.searchWrap .btn-search:hover {
  background: #6a1521;
}
@media (max-width: 699px) {
  .searchWrap .btn-search {
    position: absolute;
    right: 40px;
    top: calc(100% - 23px);
    max-width: 107px;
  }
}

.welcomeSlider .welcome-owl-video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
}
.welcomeSlider .owl-dots {
  position: absolute;
  left: auto;
  right: 0;
  width: 100%;
  bottom: 2px;
}
.welcomeSlider .owl-dots .owl-dot span {
  background-color: #fff !important;
  border: 1px solid #FFC72C;
}
.welcomeSlider .owl-dots .owl-dot.active span {
  background-color: #fff !important;
  border: 1px solid #fff;
}

.agent-card {
  border: 1px solid #eee;
  border-radius: 5px;
  position: relative;
  padding-top: 35px;
}
.agent-card .ribbon {
  position: absolute;
  background: #32c3eb;
  color: #fff;
  padding: 12px 8px 8px;
  max-width: 68px;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  line-height: 16px;
  top: -12px;
  text-shadow: 0 1px 1px #000;
}
.agent-card .ribbon::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  border-top: 15px solid #32c3eb;
  left: 0;
  top: 100%;
  right: 0;
  margin: auto;
}
.agent-card .ribbon .left-edge {
  position: absolute;
  right: 100%;
  top: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #218ba8;
  width: 0;
  height: 0;
  border-bottom: 5px solid #218ba8;
  border-left: 5px solid transparent;
}
.agent-card .ribbon .right-edge {
  position: absolute;
  left: 100%;
  top: 0;
  border-left: 7px solid #218ba8;
  border-right: 5px solid transparent;
  border-top: 7px solid transparent;
  border-bottom: 5px solid #218ba8;
  width: 0;
  height: 0;
}
.agent-card .agent-name-organisation {
  padding: 20px;
}
.agent-card .agent-name-organisation .agent-detail {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.agent-card .agent-name-organisation .agent-detail:not(:last-child) {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ededed;
}
.agent-card .agent-name-organisation .agent-detail .agent-pic {
  width: 70px;
  margin-right: 15px;
}
.agent-card .agent-name-organisation .agent-detail .agent-name {
  font-weight: 500;
}
.agent-card .agent-name-organisation .agent-detail .agent-organisation-logo {
  width: 70px;
  margin-right: 18px;
}
.agent-card .agent-name-organisation .agent-detail .agent-organisation-name {
  width: calc(100% - 100px);
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
}
.agent-card .agent-name-organisation .agent-detail .agent-organisation-name .agentSince {
  font-size: 14px;
  color: #333;
  display: block;
  font-weight: 400;
}
.agent-card .about-agent {
  border-top: 1px solid #ededed;
  padding: 15px;
}
.agent-card .about-agent h3 {
  font-size: 18px;
}
.agent-card .about-agent ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.agent-card .about-agent ul li i {
  font-size: 13px;
  margin: 1px 5px 0 0;
  color: #5BAE47;
}
.agent-card .view-profile {
  border-top: 1px solid #ededed;
  padding: 15px;
}
.agent-card .view-profile .btn-view-profile {
  background: #ce0010;
  border-radius: 25px;
  color: #fff;
  width: 100%;
}
.agent-card .view-profile .btn-view-profile:hover {
  background-color: #9e0101;
}

.report_share {
  position: relative;
}
.report_share i {
  cursor: pointer;
}
.report_share ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  right: 0;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  z-index: -1;
  overflow: hidden;
  opacity: 0;
  top: calc(100% + 25px);
  transition: all 0.3s ease-in-out;
}
.report_share ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.report_share ul li a {
  white-space: nowrap;
  text-decoration: none;
  padding: 7px 10px;
  display: block;
  color: #020202;
  transition: all 0.3s ease-in-out;
}
.report_share ul li a:hover {
  background: #eee;
  color: #333;
}
.report_share:hover ul {
  opacity: 1;
  z-index: 1;
  top: 100%;
}

.gallary-section {
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.positive {
  color: #5BAE47;
}

.negitive {
  color: #e86767;
}

.body-green-tick ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.body-green-tick ul li {
  position: relative;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.55px;
  line-height: 24px;
  color: #FFFFFF;
  padding-left: 27px;
}
.body-green-tick ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 16px;
  height: 16px;
  background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.xyz/images/tick-inside-circle.svg) no-repeat 0 0/100%;
}
.body-green-tick ul li:not(:last-child) {
  margin-bottom: 10px;
}
.body-green-tick ul.text-black li {
  color: #000;
}

.custom-icon {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 16px;
}
.custom-icon.purple {
  background-color: #202466;
}
.custom-icon.green {
  background-color: #457b3d;
}
.custom-icon.mint-green {
  background-color: #149c93;
}
.custom-icon.lite-red {
  background-color: #df6e71;
}
.custom-icon.grd-green {
  border: 2px solid #239090;
  background: rgb(92, 220, 113);
  background: linear-gradient(to bottom, rgb(92, 220, 113), rgb(35, 144, 144));
}

.hightlight {
  border-left: 3px solid transparent;
  max-width: 550px;
}
.hightlight .icon {
  width: 50px;
  height: 50px;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 23px;
}
.hightlight p {
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  font-weight: 500;
  margin: 0;
}
.hightlight.lite-red {
  border-color: #e56669;
  background: rgba(229, 102, 105, 0.062745098);
}
.hightlight.lite-red .icon {
  background-color: #e56669;
}
.hightlight.green {
  border-color: #149c93;
  background: rgba(20, 156, 147, 0.062745098);
}
.hightlight.green .icon {
  background-color: #149c93;
}

.noBgTable * {
  border: none;
  background: #ffffff;
}
.noBgTable th, .noBgTable td {
  padding: 8px;
}

ul.contactus {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  font-size: 18px;
  font-weight: 500;
}
ul.contactus a {
  text-decoration: none;
  color: #666;
}
ul.contactus a:hover {
  color: #5BAE47;
}

.mobile-friendly-modal {
  margin: 0;
  padding: 0;
}

.mobile-friendly-modal .modal-content {
  min-height: 100%;
  border-radius: 0;
}

.mobile-friendly-form input {
  font-size: 16px; /* Prevents iOS zoom */
  padding: 12px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.mobile-friendly-form button[type=submit] {
  width: 100%;
  padding: 12px;
  font-size: 16px;
}

@media (max-width: 767px) {
  .mobile-friendly-modal .modal-dialog {
    margin: 0;
    max-width: none;
    height: 100%;
  }
  .mobile-friendly-modal .modal-content {
    height: 100%;
    border: none;
  }
}
.verifyModal .modal-dialog {
  max-width: 700px;
}
.verifyModal .modal-dialog .input-otp {
  max-width: 70px;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #848484;
  margin: 0 5px;
}
.verifyModal .modal-dialog .btn-edit {
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  opacity: 1;
  padding: 0 15px;
  margin-left: 20px;
  font-size: 14px;
  line-height: 12px;
  height: 30px;
  display: flex;
  border-radius: 27px;
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
  -ms-border-radius: 27px;
  -o-border-radius: 27px;
  align-items: center;
}
.verifyModal .modal-dialog .btn-edit:hover {
  color: #5BAE47;
}

.agentWrapper .agentimg {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  min-width: 300px;
}
@media (max-width: 575px) {
  .agentWrapper .agentimg {
    min-width: 100%;
  }
}
.agentWrapper .userType {
  background: #eee;
  display: inline-flex;
  padding: 5px 10px;
  border-radius: 5px;
}
.agentWrapper .userType.pro {
  background: linear-gradient(252.05deg, #536d1b 28.8%, #d5940b 125.92%);
  color: #fff;
}
.agentWrapper .userType.pro span {
  font-weight: 500;
  margin-left: 5px;
}
.agentWrapper .agentDetails {
  font-size: 20px;
  line-height: 28px;
}
.agentWrapper .agentDetails ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.agentWrapper .agentDetails ul li:not(:last-child) {
  margin-bottom: 10px;
}
.agentWrapper .body-green-tick ul {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}
.agentWrapper .body-green-tick ul li {
  color: #000;
  margin-bottom: 10px;
}
.agentWrapper .body-green-tick ul li::before {
  top: 3px;
}
.agentWrapper .body-green-tick ul li:not(:last-child) {
  margin-right: 20px;
}
.agentWrapper ul.areas {
  padding: 0;
  margin: 0;
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.agentWrapper ul.areas li {
  background: #c6edff;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

div.lg-react-element {
  display: flex;
  -webkit-box-wrap: wrap;
  -moz-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-wrap: wrap;
  flex-wrap: wrap;
}
div.lg-sub-html {
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
}
div.lg-sub-html h4 {
  font-size: 16px;
  font-weight: 500;
}

/* HTML: <div class="loaderNew"></div> */
.loaderNew {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-right-color: #25b09b;
  animation: l15 1s infinite linear;
}
.loaderNew::before, .loaderNew::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loaderNew::after {
  margin: 8px;
  animation-duration: 3s;
}

@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}/*# sourceMappingURL=univariety.css.map */